import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';

@Directive({
    selector: '[libIfPlatformIs]',
    standalone: true,
})
export class IfPlatformIsDirective {
    public hasView = false;

    public constructor(
        private readonly platform: Platform,
        private readonly template: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
    ) {}

    @Input('libIfPlatformIs')
    public set notPlatform(platformName: Platforms | null) {
        const isPlatform =
            typeof platformName === 'string' ? this.platform.is(platformName) : false;
        const isNotPlatform = !isPlatform;

        if (isPlatform && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.template);
            this.hasView = true;
        } else if (isNotPlatform && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}

type Platforms = Parameters<Platform['is']>[0];
