import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql/language';
import { getOperationAST, print } from 'graphql';
import { GraphqlResponse } from '../errors/graphql-errors.exception';

// Todo: should probably be injectable or part of domain. Or just provide/inject the full URL.
const endpoint = '/graphql';

export abstract class GraphqlQuery<Variables, Result> {
    public abstract readonly document: DocumentNode;

    protected constructor(private readonly domain: string, private readonly http: HttpClient) {}

    public fetch(
        variables?: Variables,
        options?: HttpOptions,
    ): Observable<GraphqlResponse<Result>> {
        const operationName = getOperationAST(this.document)?.name?.value;
        const query = print(this.document);

        return this.http.post<GraphqlResponse<Result>>(
            this.domain + endpoint,
            {
                operationName,
                query,
                variables,
            },
            options,
        );
    }
}

type HttpOptions = Parameters<HttpClient['post']>[2];
