import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AccountsService } from './accounts.service';
import { firstValueFrom } from 'rxjs';
import { Account } from '@lib/shared-interface-account';
import { AuthenticationService } from '@lib/didit-authentication-data-authentication-service';
import { OnlineStatusService } from '@lib/didit-shared-data-online-status-service';

/**
 * Creates a guard that redirects to the specified URL if the user is not logged in.
 */
export function createIsLoggedInGuard(redirectUrl: string): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);
        const authenticationService = inject(AuthenticationService);
        const onlineStatusService = inject(OnlineStatusService);

        const account = await firstValueFrom(accountsService.activeAccountReference$);
        if (!account) return router.parseUrl(redirectUrl);

        // Capture the current URL for redirection after access is resolved.
        const urlTree = router.parseUrl(redirectUrl);
        urlTree.queryParams = { ...urlTree.queryParams, email: account.email, redirect: state.url };

        const isOffline = await firstValueFrom(onlineStatusService.isOffline$);
        const token = authenticationService.getAccessToken(account.authenticationId);
        // Todo: Not sure if storing 'isLoggedIn' in the account reference would be better.
        //  At the moment I am assuming that they are "logged in" when offline
        //  as long as they have tokens in local storage.
        const isLoggedIn = Boolean(token);
        if (isOffline) return isLoggedIn || urlTree;

        // If they are online, however, we need to give the authentication service a chance
        // to update the logged in status first.
        const state$ = authenticationService.watchAuthenticationState(account.authenticationId);
        const accountState = await firstValueFrom(state$);
        if (accountState?.isLoggedIn) return true;

        return urlTree;
    };
}

export function createHasCompletedRegistrationGuard(redirectUrl: string): CanActivateFn {
    return async () => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);
        const account = await firstValueFrom(accountsService.activeAccount$);
        if (hasBasicInfo(account)) return true;

        // Todo: Capture a redirect URL to potentially use after they complete onboarding.
        return router.parseUrl(redirectUrl);
    };
}

export function createHasIncompleteRegistrationGuard(redirectUrl: string): CanActivateFn {
    return async () => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);
        const account = await firstValueFrom(accountsService.activeAccount$);
        if (!hasBasicInfo(account)) return true;

        return router.parseUrl(redirectUrl);
    };
}

function hasBasicInfo(account?: Account): boolean {
    const { firstName, lastName, use, subscriberPlanId } = account ?? {};
    return Boolean(firstName && lastName && use && subscriberPlanId);
}
