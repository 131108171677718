import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, getExampleNumber } from 'libphonenumber-js/min';
import examples from 'libphonenumber-js/mobile/examples';

@Pipe({
    name: 'libExamplePhoneNumber',
    standalone: true,
})
export class ExamplePhoneNumberPipe implements PipeTransform {
    public transform(countryCode: CountryCode): string | undefined {
        return getExampleNumber(countryCode, examples)?.formatNational();
    }
}
