<ion-header class="inherit-background ion-no-border">
    <ion-toolbar id="navigation-toolbar">
        <ion-buttons slot="start">
            @if (includeSideMenu()) {
                <ion-button (click)="sideMenuService.toggleOpen()">
                    <ion-icon name="menu" slot="icon-only" />
                </ion-button>
            }
        </ion-buttons>
        <picture class="ion-hide-xl-down" slot="start">
            <img
                alt="Logo icon and name"
                class="icon-full cursor-pointer"
                routerLink="/"
                src="/assets/logos/logo-icon-name.svg"
            />
        </picture>
        <picture class="ion-hide-xl-up ion-hide-sm-down" slot="start">
            <img
                alt="Logo icon"
                class="icon-only cursor-pointer"
                routerLink="/"
                slot="start"
                src="/assets/logos/logo-icon-only.svg"
            />
        </picture>
        <ion-title *libIfPlatformIs="'mobile'"> {{ pageTitle() }} </ion-title>

        <ion-buttons
            *libIfPlatformIs="'desktop'"
            (click)="closeWhenSmall()"
            class="large-screen-navigation ion-justify-content-around"
            id="desktop-buttons"
        >
            <ng-content select="ion-button[desktopNavigationLink]" />
        </ion-buttons>

        <ion-buttons slot="end">
            <ion-menu-toggle menu="account-menu">
                <ng-content select="[accountMenuToggle]" />
            </ion-menu-toggle>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div id="menu-content-container" [class.menu-pane-visible]="sideMenuOpen()">
    <div id="menu-content">
        <!-- div required for correct transition animation -->
        <div>
            <ng-content select="[sideMenuContent]" />
        </div>
        <!-- div required for correct transition animation -->
        @if (socialLinks().length > 0) {
            <ion-footer class="menu-footer ion-no-border no-safe-area-right">
                <ion-toolbar>
                    <ion-buttons slot="start">
                        @for (link of socialLinks(); track $index) {
                            <ion-button [href]="link.href" color="dark" target="_blank">
                                <ion-icon
                                    slot="icon-only"
                                    [name]="link.iconName"
                                    [src]="link.iconSource"
                                />
                            </ion-button>
                        }
                    </ion-buttons>
                </ion-toolbar>
            </ion-footer>
        }
    </div>
    <div (click)="closeWhenSmall()" id="main-content">
        <ion-router-outlet />
        <ion-tabs>
            @if (showTabs()) {
                <ion-tab-bar *libIfPlatformIs="'mobile'" slot="bottom">
                    <ng-content select="ion-tab-button[mobileTabButton]" />
                </ion-tab-bar>
            }
        </ion-tabs>
    </div>
</div>

<ion-menu menuId="account-menu" contentId="main-content" side="end">
    <ion-header class="ion-no-border">
        <ion-toolbar class="menu-light-contrast">
            <ng-content select="[accountMenuToolbarContent]" />
        </ion-toolbar>
    </ion-header>
    <ion-content class="account-navigation-container menu-light-contrast">
        <ng-content select="[accountMenuContent]" />
    </ion-content>
    <ion-footer class="account-menu-footer ion-no-border no-safe-area-left">
        <ion-toolbar>
            <!-- ngsw.json timestamp is displayed in UTC for consistency across browsers -->
            <small class="ion-padding-horizontal">
                Build {{ version() | date: 'yyyyMMdd.HHmmss' : 'UTC' }}
            </small>
        </ion-toolbar>
    </ion-footer>
</ion-menu>
