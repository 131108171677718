import { createStringEnum, EnumValues } from '@lib/shared-util-create-enum';

export const ASSIGNMENT_STRATEGIES = ['MULTIPLE', 'SINGLE', 'SINGLE_SHARED'] as const;

/**
 * Assignment strategy enum.
 *
 * - MULTIPLE means every recipient will have an assignment.
 * - SINGLE means one person can claim the assignment.
 * - SINGLE_SHARED means everyone collaborates on only one assignment.
 */
export const AssignmentStrategy = createStringEnum(ASSIGNMENT_STRATEGIES);
export type AssignmentStrategy = EnumValues<typeof AssignmentStrategy>;

export const ACCEPTANCE_TYPES = ['ACCEPTED', 'REJECTED'] as const;

/**
 * Values for whether a role or task has been accepted or rejected.
 */
export const AcceptanceType = createStringEnum(ACCEPTANCE_TYPES);
export type AcceptanceType = EnumValues<typeof AcceptanceType>;

export const ASK_STATUSES = ['DRAFT', 'OPEN', 'ON_HOLD', 'CANCELED', 'COMPLETED'] as const;

/**
 * The status of an ask.
 *
 * - DRAFT can change to OPEN (when didit is assigned)
 * - OPEN can change to ON_HOLD | CANCELED | COMPLETED
 * - ON-HOLD can change to OPEN | CANCELED | COMPLETED
 * - CANCELED can change to OPEN
 * - COMPLETED can change to OPEN
 */
export const AskStatus = createStringEnum(ASK_STATUSES);
export type AskStatus = EnumValues<typeof AskStatus>;

export const RECIPIENT_ASK_STATUSES = [
    'REFUSED',
    'ACCEPTED',
    'IN_PROGRESS',
    'ON_HOLD',
    'CANCELED',
    'COMPLETED',
] as const;

/**
 * The status of an ask from the perspective of a recipient.
 *
 * - Assignees can have: REFUSED | ACCEPTED | IN_PROGRESS | ON_HOLD | CANCELED | COMPLETED.
 * - Watchers who are not also assignees only have: REFUSED | ACCEPTED
 * - REFUSED can change to ACCEPTED
 * - ACCEPTED | IN_PROGRESS can change to ON_HOLD | CANCELED | COMPLETED
 * - ON-HOLD can change to IN_PROGRESS | CANCELED | COMPLETED
 * - CANCELED can change to IN_PROGRESS
 * - COMPLETED can change to IN_PROGRESS
 */
export const RecipientAskStatus = createStringEnum(RECIPIENT_ASK_STATUSES);
export type RecipientAskStatus = EnumValues<typeof RecipientAskStatus>;
