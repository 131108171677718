import { createStringEnum, EnumValues } from '@lib/shared-util-create-enum';

export const ASSIGNMENT_STRATEGIES = ['EVERYONE', 'ANYONE', 'COLLABORATIVE'] as const;

/**
 * Assignment strategy enum.
 *
 * - EVERYONE means every recipient will have an assignment.
 * - ANYONE means one person can claim the assignment.
 * - COLLABORATIVE means everyone collaborates on only one assignment.
 */
export const AssignmentStrategy = createStringEnum(ASSIGNMENT_STRATEGIES);
export type AssignmentStrategy = EnumValues<typeof AssignmentStrategy>;

export const ACCEPTANCE_TYPES = ['ACCEPTED', 'DECLINED'] as const;

export const AcceptanceType = createStringEnum(ACCEPTANCE_TYPES);
export type AcceptanceType = EnumValues<typeof AcceptanceType>;

export const ASK_STATUSES = ['DRAFT', 'OPEN', 'ON_HOLD', 'CANCELED', 'COMPLETED'] as const;

/**
 * The status of an ask.
 *
 * - DRAFT can change to OPEN (when didit is assigned)
 * - OPEN can change to ON_HOLD | CANCELED | COMPLETED
 * - ON-HOLD can change to OPEN | CANCELED | COMPLETED
 * - CANCELED can change to OPEN
 * - COMPLETED can change to OPEN
 */
export const AskStatus = createStringEnum(ASK_STATUSES);
export type AskStatus = EnumValues<typeof AskStatus>;

export const RECIPIENT_ASK_STATUSES = ['DECLINED', 'OPEN', 'CANCELED', 'COMPLETED'] as const;

/**
 * The status of an ask from the perspective of a recipient.
 *
 * - Assignees can have: DECLINED | OPEN | CANCELED | COMPLETED.
 * - Watchers who are not also assignees only have: DECLINED | ACCEPTED
 *   Todo: This might no longer be true about "DECLINED"
 * - DECLINED can change to OPEN
 * - OPEN can change to CANCELED | COMPLETED
 */
export const RecipientAskStatus = createStringEnum(RECIPIENT_ASK_STATUSES);
export type RecipientAskStatus = EnumValues<typeof RecipientAskStatus>;
