<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="closeModal()">Cancel</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-list>
        @for (country of countries; track country.code) {
            <ion-item (click)="closeModal(country)" lines="full" class="flex-container">
                <img
                    [src]="country.flagUrl"
                    class="ion-margin-end"
                    loading="lazy"
                    width="30"
                    height="23"
                />
                <span class="ion-margin-end">{{ country.fullName ?? 'unknown' }}</span>
                <span class="calling-code">{{ country.callingCode }}</span>
            </ion-item>
        }
    </ion-list>
</ion-content>
