import { BasicAccountInfo } from '@lib/shared-interface-account';
import { Uuid } from '@lib/shared-interface-utility-types';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { gql } from 'graphql-tag';
import { GraphqlMutation } from './graphql-mutation';

@Injectable({ providedIn: 'root' })
export class SaveBasicAccountInfoGql extends GraphqlMutation<Variables, Result> {
    public override readonly document = gql`
        mutation SaveBasicAccountInfo($basicAccountInfo: BasicAccountInfoGqlInput!) {
            saveBasicAccountInfo(basicAccountInfo: $basicAccountInfo) {
                id
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Variables = { basicAccountInfo: BasicAccountInfo };
type Result = Record<'saveBasicAccountInfo', { id: Uuid }>;
