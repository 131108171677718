import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { map, Observable } from 'rxjs';
import { I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX } from './i18n.constants';

export class TranslationLoaderService implements TranslateLoader {
    public constructor(
        private prefix: string = I18N_ASSETS_PREFIX,
        private suffix: string = I18N_ASSETS_SUFFIX,
        private http: HttpClient,
    ) {}

    public getTranslation(languageCode: string): Observable<Record<string, unknown>> {
        return new TranslateHttpLoader(this.http, this.prefix, this.suffix)
            .getTranslation(languageCode)
            .pipe(map(validateTranslationFileKeys));
    }
}

function validateTranslationFileKeys(translationFile: unknown): Record<string, unknown> {
    if (!isStringKeyedRecord(translationFile)) {
        throw new Error(
            'There are unknown key types in the translation file. Keys must be strings!',
        );
    }
    return translationFile;
}

function isStringKeyedRecord(value: unknown): value is Record<string, unknown> {
    if (value == undefined) {
        return false;
    }
    if (!(typeof value === 'object')) {
        return false;
    }
    const keys = Object.keys(value);
    return keys.every((key) => typeof key === 'string');
}
