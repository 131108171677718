import { Uuid } from '@lib/shared-interface-utility-types';
import { Ask } from '@lib/shared-interface-ask';
import { MangoQuery, MangoQuerySelector, MangoQuerySortPart } from 'rxdb';

export type AskFilters = {
    id?: Uuid;
    namedFilter?: keyof typeof NAMED_FILTERS;
    searchText?: string;
    // There will be other filters eventually such as date range
};

export function createAskQuery(accountId: Uuid, filter: AskFilters): MangoQuery<Ask> {
    const selector: MangoQuerySelector<Ask> = {};
    const sort: MangoQuerySortPart<Ask>[] = [
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'whenRequestedBy.date': 'asc' },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'whenRequestedBy.time': 'asc' },
        { createdAt: 'asc' },
    ];

    if (filter.id || filter.namedFilter) {
        selector.$and = [];
    }
    if (filter.id) {
        selector.$and?.push({ id: filter.id });
    }
    if (filter.namedFilter) {
        const getFilter = NAMED_FILTERS[filter.namedFilter];
        selector.$and?.push(...getFilter(accountId));
    }
    if (filter.searchText) {
        const $regex = getSearchPattern(filter.searchText);
        const $options = 'i';

        selector.$or = [
            { title: { $regex, $options } },
            { description: { $regex, $options } },
            // Todo: Creator name/email is not available at this time.
            // { creator: { name: { $regex, $options } } },
            // { creator: { email: { $regex, $options } } },
            { recipients: { $elemMatch: { name: { $regex, $options } } } },
            { recipients: { $elemMatch: { email: { $regex, $options } } } },
        ];
    }

    return { selector, sort };
}

export const NAMED_FILTERS = {
    trash: () => [{ trashedAt: { $exists: true } }],
    watching: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        // Todo: Need to add current status property.
        { currentStatus: 'OPEN' },
        { recipients: { $elemMatch: { accountId, watcher: true } } },
    ],
    todo: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        // Todo: Need to add current status property.
        { currentStatus: 'OPEN' },
        { recipients: { $elemMatch: { accountId, assignee: true } } },
    ],
    draft: () => [
        { trashedAt: { $exists: false } },
        // Todo: Need to add current status property.
        { currentStatus: 'DRAFT' },
    ],
    done: () => [
        { trashedAt: { $exists: false } },
        // Todo: Need to add current status property.
        { currentStatus: 'COMPLETED' },
    ],
} satisfies Record<string, (accountId: Uuid) => MangoQuerySelector<Ask>[]>;

export function getSearchPattern(searchText: string): string {
    // An attempt to match partial words in order of being written.
    // e.g. searchText = " hel   wor " could match the text "Hello World!"
    const whitespacePattern = /\s+/;
    // e.g. This gives us ['hel', 'wor'].
    const searchTerms = searchText.trim().split(whitespacePattern);
    // The capturing groups are not strictly necessary,
    // but it could later allow us to highlight the matched words when presenting results.
    // e.g. This finally gives us '(hel).*(wor)'.
    return searchTerms.map((term) => `(${term})`).join('.*');
}
