import { InjectionToken, Provider } from '@angular/core';
import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';

export type AuthenticationConfiguration = ICognitoUserPoolData;

const AUTHENTICATION_CONFIGURATION_TOKEN = new InjectionToken<AuthenticationConfiguration>(
    'AuthenticationConfiguration',
);

export const COGNITO_USER_POOL_PROVIDER: Provider = {
    provide: CognitoUserPool,
    // if there is more than one parameter for `CognitoUserPool`
    // then a factory function might be more appropriate.
    useClass: CognitoUserPool,
    deps: [AUTHENTICATION_CONFIGURATION_TOKEN],
};

export function provideAuthenticationConfiguration(
    configuration: AuthenticationConfiguration,
): Provider[] {
    const configurationProvider = {
        provide: AUTHENTICATION_CONFIGURATION_TOKEN,
        useValue: configuration,
    };
    return [COGNITO_USER_POOL_PROVIDER, configurationProvider];
}
