import { Observable, ReplaySubject } from 'rxjs';
import { Uuid } from '@lib/shared-interface-utility-types';
import { ItemStore } from '@lib/shared-data-item-stores';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE_TOKEN } from '@lib/didit-shared-util-miscellaneous-providers';

const storageKey = 'activeAccountId';

@Injectable({ providedIn: 'root' })
export class ActiveAccountIdStore implements ItemStore<Uuid | undefined> {
    private readonly item = new ReplaySubject<Uuid | undefined>(1);
    public readonly item$: Observable<Uuid | undefined> = this.item.asObservable();

    public constructor(@Inject(SESSION_STORAGE_TOKEN) private readonly storage: Storage) {}

    public get storedId(): Uuid | undefined {
        const id = this.storage.getItem(storageKey);
        return id ?? undefined;
    }

    public update(item: Uuid | undefined): Promise<void> {
        typeof item === 'string'
            ? this.storage.setItem(storageKey, item)
            : this.storage.removeItem(storageKey);
        this.item.next(item);

        return Promise.resolve();
    }
}
