import {
    Enum,
    Format,
    JsonSchema,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import { DateOfBirthSchema } from './date-of-birth.schema';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { Account, AccountUse, DateOfBirth } from '@lib/shared-interface-account';

@JsonSchema({
    title: 'Account Schema',
    description: 'A user account.',
})
class AccountSchema implements Account {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Optional()
    public readonly label?: string;

    @Optional()
    public readonly firstName?: string;

    @Optional()
    public readonly lastName?: string;

    @Required()
    @Format('email')
    public readonly email!: Email;

    @Optional()
    public readonly phone?: Phone;

    @Optional()
    @Type(DateOfBirthSchema)
    public readonly dateOfBirth?: DateOfBirth;

    @Optional()
    @Enum(AccountUse)
    public readonly use?: AccountUse;

    @Optional()
    public readonly homeCountryCode?: string;

    @Optional()
    public readonly languageCode?: string;

    @Optional()
    public readonly avatar?: string;

    @Optional()
    public readonly subscriberPlanId?: Uuid;

    @Required()
    @Type('integer')
    public readonly updatedAt!: number;
}

export const ACCOUNT_SCHEMA = generateRxdbSchema(AccountSchema, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
