import type { RxCleanupPolicy, RxDatabaseCreator } from 'rxdb';
import { Capacitor } from '@capacitor/core';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';

export function getCommonDatabaseOptions() {
    const storage = getRxStorageDexie();
    // https://capacitorjs.com/docs/core-apis/web#getplatform
    // https://rxdb.info/rx-database.html#multiinstance
    const multiInstance = Capacitor.getPlatform() === 'web';
    // https://rxdb.info/rx-database.html#eventreduce
    const eventReduce = true;
    // https://rxdb.info/cleanup.html
    const cleanupPolicy = {
        minimumDeletedTime: 1000 * 60 * 60 * 24, // 1 day [default=one month]
        // minimumCollectionAge: 1000 * 60, // 1 minute [default=60 seconds]
        runEach: 1000 * 60 * 60, // 1 hour [default=5 minutes]
        // awaitReplicationsInSync: true, // [default=true]
        // waitForLeadership: true, // [default=true]
    } satisfies Partial<RxCleanupPolicy>;

    return {
        eventReduce,
        multiInstance,
        storage,
        cleanupPolicy,
    } satisfies Partial<RxDatabaseCreator>;
}
