import { APP_INITIALIZER, Provider } from '@angular/core';
import { I18nService } from './i18n.service';
import { HttpClient } from '@angular/common/http';
import { TranslationLoaderService } from './translation-loader.service';
import { I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX } from './i18n.constants';

export function initializeApplication(i18nService: I18nService): () => Promise<void> {
    return async () => await i18nService.initLanguage();
}

export const I18N_APP_INITIALIZER: Provider = {
    provide: APP_INITIALIZER,
    useFactory: initializeApplication,
    multi: true,
    deps: [I18nService],
};

export function httpLoaderFactory(http: HttpClient) {
    return new TranslationLoaderService(I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX, http);
}
