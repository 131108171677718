import { Component, computed, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Uuid } from '@lib/shared-interface-utility-types';
import {
    IonAvatar,
    IonButton,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonMenuToggle,
    IonRouterLink,
    IonTabButton,
    IonText,
} from '@ionic/angular/standalone';
import { NgTemplateOutlet } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppService } from '@lib/didit-app-data-app-service';
import { AuthenticationService } from '@lib/didit-authentication-data-authentication-service';
import { AccountAvatarComponent } from '@lib/didit-accounts-feature-account-avatar';
import { AccountsService } from '@lib/didit-accounts-data-accounts-service';
import { OnlineStatusService } from '@lib/didit-shared-data-online-status-service';
import { addIcons } from 'ionicons';
import {
    addOutline,
    chatboxEllipsesOutline,
    chatbubblesOutline,
    checkmarkCircleOutline,
    closeOutline,
    compassOutline,
    documentTextOutline,
    earOutline,
    helpOutline,
    informationCircleOutline,
    menu,
    newspaperOutline,
    peopleOutline,
    personCircleOutline,
    trashOutline,
} from 'ionicons/icons';
import { BrandedNavigationComponent } from '@lib/didit-navigation-feature-branded-navigation';
import { SubscriberPlan } from '@lib/shared-interface-subscriber-plan';
import { toSignal } from '@angular/core/rxjs-interop';
import { SubscriberPlansService } from '@lib/didit-subscriber-plans-data-subscriber-plans-service';

@Component({
    standalone: true,
    selector: 'lib-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    imports: [
        AccountAvatarComponent,
        BrandedNavigationComponent,
        IonAvatar,
        IonButton,
        IonIcon,
        IonItem,
        IonItemDivider,
        IonItemGroup,
        IonLabel,
        IonList,
        IonMenuToggle,
        IonRouterLink,
        IonTabButton,
        IonText,
        NgTemplateOutlet,
        RouterModule,
        TranslateModule,
    ],
})
export class NavigationComponent implements OnInit {
    protected readonly account = toSignal(this.accountsService.activeAccount$);
    protected readonly plans = toSignal(this.subscriberPlansService.plans$);
    protected readonly accountPlan = computed(() => {
        const plans = this.plans();
        const account = this.account();

        if (!account || !plans) return;
        const matchesAccountPlan = (plan: SubscriberPlan) => plan.id === account.subscriberPlanId;
        const accountPlan = plans.find(matchesAccountPlan);

        return accountPlan?.type;
    });
    protected readonly activeAccountReference = toSignal(this.appService.activeAccountReference$);
    protected readonly inactiveAccountReferences = toSignal(
        this.appService.inactiveAccountReferences$,
    );
    protected readonly isOffline = toSignal(this.onlineStatus.isOffline$);

    public constructor(
        private readonly accountsService: AccountsService,
        private readonly appService: AppService,
        private readonly authenticationService: AuthenticationService,
        private readonly onlineStatus: OnlineStatusService,
        private readonly router: Router,
        private readonly subscriberPlansService: SubscriberPlansService,
    ) {
        addIcons({
            addOutline,
            chatboxEllipsesOutline,
            chatbubblesOutline,
            checkmarkCircleOutline,
            closeOutline,
            compassOutline,
            documentTextOutline,
            earOutline,
            helpOutline,
            informationCircleOutline,
            menu,
            newspaperOutline,
            peopleOutline,
            personCircleOutline,
            trashOutline,
        });
    }

    public ngOnInit(): void {
        this.router.events.subscribe((navigationEvent) => {
            if (navigationEvent instanceof NavigationEnd)
                void this.accountsService.saveLastViewedPage(navigationEvent.url);
        });
    }

    protected setActiveAccount(accountId: Uuid | undefined): Promise<void> {
        return this.accountsService.setActive(accountId);
    }

    protected async logOutAllAccounts(): Promise<void> {
        this.authenticationService.signOutAllAccounts();
        void this.accountsService.clearAllLastViewedPages();

        const activeAccount = await firstValueFrom(this.accountsService.activeAccount$);

        await this.setActiveAccount(undefined);
        await this.router.navigate(['account', 'login'], {
            queryParams: { email: activeAccount?.email },
        });
    }
}
