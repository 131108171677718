@if (imageSource()) {
    <ion-avatar>
        <img
            [src]="imageSource()"
            [style.height.px]="width()"
            [style.width.px]="width()"
            alt="user avatar"
            class="avatar-image"
        />
    </ion-avatar>
} @else {
    @if (titleInitial(); as titleInitial) {
        <div
            [style.width.px]="width()"
            [style.height.px]="width()"
            [style.font-size]="fontSize()"
            [style.background-color]="backgroundColor()"
            class="account-avatar-icon"
        >
            {{ titleInitial }}
        </div>
    } @else {
        <ion-avatar>
            <ion-icon
                [style.font-size]="fontSize()"
                [style.height.px]="width()"
                [style.width.px]="width()"
                color="primary"
                name="person-circle-outline"
            />
        </ion-avatar>
    }
}
