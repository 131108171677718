import { LanguageSelection } from './i18n.interfaces';

export const I18N_ASSETS_PREFIX = `/assets/i18n/`;

export const I18N_ASSETS_SUFFIX = `.json`;

export const STORAGE_KEY_FOR_LANGUAGE_CODE = 'lang';

export const LANGUAGE_DEFAULT = { code: `en`, name: `English`, culture: `en-US` };

export const LANGUAGE_LIST: LanguageSelection[] = [
    { code: `en`, name: `English`, culture: `en-US` },
    { code: `es`, name: `Español`, culture: `es-MX` },
    { code: `fr`, name: `Français`, culture: `fr-FR` },
    { code: `it`, name: `Italiano`, culture: `it-IT` },
    { code: `de`, name: `Deutsch`, culture: `de-DE` },
    { code: `pt`, name: `Português`, culture: `pt-PT` },
];
