import { Uuid } from '@lib/shared-interface-utility-types';
import { removeNulls } from '../../shared/remove-nulls';
import { RxGraphQLReplicationPullQueryBuilder } from 'rxdb';
import { AccountCollection } from '../get-account-database';
import { getPullStreamQueryBuilder } from '../account-database-replication-helpers';
import { ReplicatedAccount } from '@lib/shared-interface-account';
import { ReplicationPushRow } from '@lib/shared-interface-rxdb-replication-types';

export function createAccountReplicationOptions(accountId: Uuid, collection: AccountCollection) {
    // This is unique to accounts.
    // Other collections should have a much higher batch size.
    const limit = 1;
    const pullQueryBuilder = getPullQueryBuilder(PULL_ACCOUNT_GQL, { accountId });
    const pushQueryBuilder = getPushQueryBuilder(PUSH_ACCOUNT_GQL, {
        accountId,
    });
    const pullStreamQueryBuilder = getPullStreamQueryBuilder(STREAM_ACCOUNT_GQL, {
        accountId,
    });

    return {
        collection,
        pull: {
            queryBuilder: pullQueryBuilder,
            streamQueryBuilder: pullStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
            includeWsHeaders: true,
        },
        push: {
            queryBuilder: pushQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
        },
        deletedField: 'deleted',
        live: true,
        // 5 seconds
        retryTime: 1000 * 5,
        waitForLeadership: true,
        autoStart: true,
    };
}

// Account stands out from other pull queries because limit is unnecessary.
function getPullQueryBuilder<Checkpoint>(
    query: string,
    constants: {
        accountId: Uuid;
    },
): RxGraphQLReplicationPullQueryBuilder<Checkpoint> {
    const { accountId } = constants;
    return (checkpoint?: Checkpoint) => ({
        query,
        variables: { accountId, checkpoint },
    });
}

function getPushQueryBuilder(query: string, constants: { accountId: Uuid }) {
    const { accountId } = constants;
    return (rows: ReplicationPushRow<ReplicatedAccount>[]) => {
        return {
            query,
            variables: { accountId, rows },
        };
    };
}

const ACCOUNT_CHECKPOINT_GQL_FRAGMENT = /* GraphQL */ `
    fragment AccountCheckpointProperties on AccountReplicationCheckpoint {
        updatedAt
    }
`;

const ACCOUNT_GQL_FRAGMENT = /* GraphQL */ `
    fragment AccountProperties on ReplicatedAccount {
        # common to replication
        id
        updatedAt
        deleted
        # specific to account
        dateOfBirth {
            day
            month
            year
        }
        email
        firstName
        lastName
        homeCountryCode
        label
        languageCode
        phone
        use
        avatar
        subscriberPlanId
    }
`;

const PULL_ACCOUNT_GQL = /* GraphQL */ `
    ${ACCOUNT_CHECKPOINT_GQL_FRAGMENT}
    ${ACCOUNT_GQL_FRAGMENT}
    query PullAccount($accountId: ID!, $checkpoint: AccountReplicationCheckpointInput) {
        accountPull(accountId: $accountId, checkpoint: $checkpoint) {
            checkpoint {
                ...AccountCheckpointProperties
            }
            documents {
                ...AccountProperties
            }
        }
    }
`;

const PUSH_ACCOUNT_GQL = /* GraphQL */ `
    ${ACCOUNT_GQL_FRAGMENT}
    mutation PushAccount($accountId: ID!, $rows: [AccountReplicationPushRow!]!) {
        pushAccount(accountId: $accountId, rows: $rows) {
            ...AccountProperties
        }
    }
`;

const STREAM_ACCOUNT_GQL = /* GraphQL */ `
    ${ACCOUNT_CHECKPOINT_GQL_FRAGMENT}
    ${ACCOUNT_GQL_FRAGMENT}
    subscription StreamAccount($accountId: ID!, $authorization: String!) {
        accountChanged(accountId: $accountId, authorization: $authorization) {
            checkpoint {
                ...AccountCheckpointProperties
            }
            documents {
                ...AccountProperties
            }
        }
    }
`;
