import { Injectable } from '@angular/core';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';
import { PromisifiedCognitoUser } from './promisified-cognito-user';

@Injectable({
    providedIn: 'root',
})
export class CognitoObjectFactory {
    public constructor(private readonly userPool: CognitoUserPool) {}

    public getCurrentCognitoUser(): PromisifiedCognitoUser {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser == undefined) throw new Error('No user signed in.');

        return new PromisifiedCognitoUser(cognitoUser);
    }

    public getCognitoUser(username: string): PromisifiedCognitoUser {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        const credentials = { Username: username, Pool: this.userPool };
        const cognitoUser = new CognitoUser(credentials);
        return new PromisifiedCognitoUser(cognitoUser);
    }

    public getAuthenticationDetails(
        configuration: IAuthenticationDetailsData,
    ): AuthenticationDetails {
        return new AuthenticationDetails(configuration);
    }

    public createUserAttribute(name: string, value: string): CognitoUserAttribute {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        const data = { Name: name, Value: value };
        return new CognitoUserAttribute(data);
    }
}
