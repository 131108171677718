import { AccountDatabase } from './get-account-database';
import { DateTime } from 'luxon';
import { MangoQueryNoLimit } from 'rxdb';
import { Ask } from '@lib/shared-interface-ask';
import { Subscription, timer } from 'rxjs';

export class AccountDatabaseJanitor {
    private constructor(private readonly database: AccountDatabase) {}

    private async cleanup(maxAge: number) {
        const olderThanTimestamp = DateTime.now().minus({ milliseconds: maxAge }).valueOf();
        const filter: MangoQueryNoLimit<Ask> = {
            selector: { trashedAt: { $lt: olderThanTimestamp } },
        };
        const trashedAsks = await this.database.asks.find(filter).exec();
        if (trashedAsks.length === 0) return;

        const askIds = trashedAsks.map((ask) => ask.id);
        await this.database.asks.bulkRemove(askIds);
    }

    protected maintenanceSubscription?: Subscription;

    public startMaintenance(frequency: number, maxAge: number) {
        this.maintenanceSubscription?.unsubscribe();

        const cleanup$ = timer(0, frequency);
        this.maintenanceSubscription = cleanup$.subscribe(() => void this.cleanup(maxAge));
    }

    public static createFor(database: AccountDatabase) {
        return new AccountDatabaseJanitor(database);
    }
}
