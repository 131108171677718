import { Constructable, generateJsonSchema } from 'ts-decorator-json-schema-generator';
import { RxJsonSchema } from 'rxdb';

/**
 * Generate an RxDB schema from a decorated class.
 *
 * This uses `ts-decorator-json-schema-generator` to generate the JSON Schema v7
 * so that we don't have to write it by hand.
 *
 * @param sourceClass The decorated class from which to extract the JSON Schema.
 * @param options Additional RxDB schema options which differ from JSON Schema v7.
 */
export function generateRxdbSchema<Entity>(
    sourceClass: Constructable<Entity>,
    options: GenerateOptions<Entity>,
): RxJsonSchema<Entity> {
    // We're only grabbing the parts we need
    // because the JSON schema used by RxDB is a variation of a proper JSON schema;
    // using the class approach, however,
    // should increase the supportability/readability of future schema changes
    const { title, description, type, properties, required } = generateJsonSchema(sourceClass);

    return {
        title,
        description,
        // without these overrides the assignment fails type checks because JSONSchema7 is unspecific
        // while RxJsonSchema is more specific
        type: type as RxJsonSchema<Entity>['type'],
        properties: properties as RxJsonSchema<Entity>['properties'],
        required: required as RxJsonSchema<Entity>['required'],
        ...options,
    };
}

/**
 * These fields are generated by `ts-decorator-json-schema-generator`.
 *
 * If you pick additional fields to take from generated json schema, you should add them to this union.
 */
type GeneratedFields = 'title' | 'description' | 'type' | 'properties' | 'required';

/**
 * Extracts the remaining, un-generated options which will still need to be set.
 *
 * These fields generated by `ts-decorator-json-schema-generator` can still be overwritten.
 */
type GenerateOptions<Entity> = Partial<Pick<RxJsonSchema<Entity>, GeneratedFields>> &
    Omit<RxJsonSchema<Entity>, GeneratedFields>;
