import { Enum, JsonSchema, Required } from 'ts-decorator-json-schema-generator';
import { Uuid } from '@lib/shared-interface-utility-types';
import { RenewalStrategy, SubscriberPlanPrice } from '@lib/shared-interface-subscriber-plan';
import { UuidFormat } from '../../shared/uuid-format.decorator';

@JsonSchema({
    title: 'SubscriberPlanPrice Schema',
    description: 'A pricing plan for a particular subscription plan.',
})
export class SubscriberPlanPriceSchema implements SubscriberPlanPrice {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Required()
    @Enum(RenewalStrategy)
    public readonly renewalStrategy!: RenewalStrategy;

    @Required()
    public readonly price!: number;
}
