// RxDB development plugins can be imported and enabled in this file.
console.log(`RxDB development plugins loading ...`);

// This is only necessary for the dev-mode plugin to work (so far).
import './rxdb-polyfills';

// import production plugins
import './rxdb-plugins.prod';

// Enabling the RxDB dev-mode plugin should only be done in development
// as it significantly increases the bundle size.
// This is the whole reason for keeping dev plugins in a separate file.
import { addRxPlugin } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';

// Adapted from https://rxdb.info/dev-mode.html#usage-with-angular.
// Note that this needs to happen before the first database is created.
addRxPlugin(RxDBDevModePlugin);

console.log(`RxDB development plugins loaded !`);
