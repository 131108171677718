import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CommonMissingTranslationHandler implements MissingTranslationHandler {
    private dotSyntaxStringMatcher = /\w+\.\w+/;
    public handle(parameters: MissingTranslationHandlerParams): string {
        if (
            this.dotSyntaxStringMatcher.test(parameters.key) &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            !parameters.translateService.translations?.en?.[parameters.key]
        ) {
            // TODO: returning the key to display and adding a console warning.
            //  We may revisit this in the future to add some notification or logging of missing translation events
            console.warn(`Lack of translation for "${parameters.key}"`);
        }
        return parameters.key;
    }
}
