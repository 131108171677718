import type { Observable } from 'rxjs';

/**
 * Return the immediate value
 * from the provided observable.
 *
 * If there is no immediate value,
 * `isImmediate` will be false.
 *
 * @param observable
 */
export function getImmediateValueFrom<Value>(
    observable: Observable<Value>,
): ImmediateValueResult<Value> {
    let result: ImmediateValueResult<Value> = { isImmediate: false };
    const handleNext = (value: Value) => (result = { isImmediate: true, value });
    const subscription = observable.subscribe({ next: handleNext });

    subscription.unsubscribe();

    return result;
}

type ImmediateValueResult<Value> =
    | {
          isImmediate: true;
          value: Value;
      }
    | {
          isImmediate: false;
      };
