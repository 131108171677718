import {
    AuthenticationDetails,
    ChallengeName,
    ClientMetadata,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserSession,
    ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

export class PromisifiedCognitoUser {
    public constructor(private readonly cognitoUser: CognitoUser) {}

    public authenticateUser(
        authenticationDetails: AuthenticationDetails,
    ): Promise<AuthenticationResult> {
        // There is only spot in the source code which makes me concerned
        // about modeling this callback configuration as a promise (one request, one response):
        // https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js#L664.
        // In all other location, when one of the callbacks is called, that's the end of the line.
        // On this line, however, they don't return, which could be a problem for calling additional callbacks.
        // I suspect it is a bug, but I'm uncertain.
        return new Promise((resolve) => {
            const callbacks = createAuthenticateUserCallbacks(resolve);
            this.cognitoUser.authenticateUser(authenticationDetails, callbacks);
        });
    }

    public confirmPassword(
        verificationCode: string,
        newPassword: string,
        clientMetadata?: ClientMetadata,
    ): Promise<ConfirmPasswordResult> {
        return new Promise((resolve) => {
            const callbacks = createConfirmPasswordCallbacks(resolve);
            this.cognitoUser.confirmPassword(
                verificationCode,
                newPassword,
                callbacks,
                clientMetadata,
            );
        });
    }

    public confirmRegistration(
        code: string,
        forceAliasCreation: boolean,
        clientMetadata?: ClientMetadata,
    ): Promise<ConfirmRegistrationResult> {
        return new Promise((resolve) => {
            const callback = createNodeCallback(resolve);
            this.cognitoUser.confirmRegistration(
                code,
                forceAliasCreation,
                callback,
                clientMetadata,
            );
        });
    }

    public forgotPassword(clientMetadata?: ClientMetadata): Promise<ForgotPasswordResult> {
        return new Promise((resolve) => {
            const callbacks = createForgotPasswordCallbacks(resolve);
            this.cognitoUser.forgotPassword(callbacks, clientMetadata);
        });
    }

    public getSession(): Promise<CognitoUserSessionResult> {
        return new Promise((resolve) => {
            const callback = createNodeCallback(resolve);
            this.cognitoUser.getSession(callback);
        });
    }

    public getUserAttributes(): Promise<GetUserAttributesResult> {
        return new Promise((resolve) => {
            const callback = createNodeCallback(resolve);
            this.cognitoUser.getUserAttributes(callback);
        });
    }

    public refreshSession(
        refreshToken: CognitoRefreshToken,
        clientMetadata?: ClientMetadata,
    ): Promise<CognitoUserSessionResult> {
        return new Promise((resolve) => {
            const callback = createNodeCallback(resolve);
            this.cognitoUser.refreshSession(refreshToken, callback, clientMetadata);
        });
    }

    public resendConfirmationCode(
        clientMetadata?: ClientMetadata,
    ): Promise<ResendConfirmationCodeResult> {
        return new Promise((resolve) => {
            const callback = createNodeCallback(resolve);
            this.cognitoUser.resendConfirmationCode(callback, clientMetadata);
        });
    }

    public signOut(): Promise<void> {
        return new Promise((resolve) => this.cognitoUser.signOut(resolve));
    }

    public updateAttributes(
        attributes: (CognitoUserAttribute | ICognitoUserAttributeData)[],
        clientMetadata?: ClientMetadata,
    ): Promise<UpdateAttributesResult> {
        return new Promise((resolve) => {
            const callback = createUpdateAttributesCallback(resolve);
            this.cognitoUser.updateAttributes(attributes, callback, clientMetadata);
        });
    }

    public getUsername(): string {
        return this.cognitoUser.getUsername();
    }

    public verifyAttribute(
        attributeName: string,
        confirmationCode: string,
    ): Promise<VerifyAttributeResult> {
        return new Promise((resolve) => {
            const callbacks = createVerifyAttributeCallbacks(resolve);
            this.cognitoUser.verifyAttribute(attributeName, confirmationCode, callbacks);
        });
    }
}

function createAuthenticateUserCallbacks(
    resolve: (result: AuthenticationResult) => void,
): Parameters<CognitoUser['authenticateUser']>[1] {
    return {
        onSuccess: (session, userConfirmationNecessary) =>
            resolve({ type: 'success', session, userConfirmationNecessary }),
        onFailure: (error: unknown) => resolve({ type: 'failure', error }),
        newPasswordRequired: (userAttributes: unknown, requiredAttributes: unknown) =>
            resolve({ type: 'newPasswordRequired', userAttributes, requiredAttributes }),
        mfaRequired: (challengeName, challengeParameters: unknown) =>
            resolve({ type: 'mfaRequired', challengeName, challengeParameters }),
        totpRequired: (challengeName, challengeParameters: unknown) =>
            resolve({ type: 'totpRequired', challengeName, challengeParameters }),
        customChallenge: (challengeParameters: unknown) =>
            resolve({ type: 'customChallenge', challengeParameters }),
        mfaSetup: (challengeName, challengeParameters: unknown) =>
            resolve({ type: 'mfaSetup', challengeName, challengeParameters }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        selectMFAType: (challengeName, challengeParameters: unknown) =>
            resolve({ type: 'selectMFAType', challengeName, challengeParameters }),
    };
}

// Todo: Any unknowns below would be better off if we could identify reasonable types.
type AuthenticationResult =
    | NamedResult<'success', { session: CognitoUserSession; userConfirmationNecessary?: boolean }>
    // Todo: Have we ever seen anything other than an error object for this?
    //  If not, we can type this as Error instead of unknown and it will simplify some logic.
    | NamedResult<'failure', { error: unknown }>
    | NamedResult<'newPasswordRequired', { userAttributes: unknown; requiredAttributes: unknown }>
    | NamedResult<'mfaRequired', { challengeName: ChallengeName; challengeParameters: unknown }>
    | NamedResult<'totpRequired', { challengeName: ChallengeName; challengeParameters: unknown }>
    | NamedResult<'customChallenge', { challengeParameters: unknown }>
    | NamedResult<'mfaSetup', { challengeName: ChallengeName; challengeParameters: unknown }>
    | NamedResult<'selectMFAType', { challengeName: ChallengeName; challengeParameters: unknown }>;

function createConfirmPasswordCallbacks(
    resolve: (result: ConfirmPasswordResult) => void,
): Parameters<CognitoUser['confirmPassword']>[2] {
    return {
        onSuccess: (data) => resolve({ type: 'success', data }),
        onFailure: (error) => resolve({ type: 'failure', error }),
    };
}

type ConfirmPasswordResult = NodeCallbackResult<string>;

function createForgotPasswordCallbacks(
    resolve: (result: ForgotPasswordResult) => void,
): Parameters<CognitoUser['forgotPassword']>[0] {
    return {
        onSuccess: (data: unknown) => resolve({ type: 'success', data }),
        onFailure: (error: Error) => resolve({ type: 'failure', error }),
        inputVerificationCode: (data: unknown) => resolve({ type: 'inputVerificationCode', data }),
    };
}

type ForgotPasswordResult =
    | NodeCallbackResult
    | NamedResult<'inputVerificationCode', { data: unknown }>;

function createUpdateAttributesCallback(
    resolve: (result: UpdateAttributesResult) => void,
    unknownErrorMessage = 'An unknown error occurred.',
): Parameters<CognitoUser['updateAttributes']>[1] {
    return (error, result, details?: unknown) => {
        if (result) return resolve({ type: 'success', result, details });

        error ??= new Error(unknownErrorMessage);
        resolve({ type: 'failure', error, details });
    };
}

type UpdateAttributesResult<Details = unknown> =
    | NamedResult<'success', { result: string; details?: Details }>
    | NamedResult<'failure', { error: Error; details?: Details }>;

function createVerifyAttributeCallbacks(
    resolve: (result: VerifyAttributeResult) => void,
): Parameters<CognitoUser['verifyAttribute']>[2] {
    return {
        onSuccess: (data) => resolve({ type: 'success', data }),
        onFailure: (error) => resolve({ type: 'failure', error }),
    };
}

type VerifyAttributeResult = NodeCallbackResult<string>;

function createNodeCallback<DataType, ErrorType = Error>(
    resolve: (result: NodeCallbackResult<DataType, ErrorType>) => void,
    unknownErrorMessage = 'An unknown error occurred.',
) {
    return (error: ErrorType | Error | null | undefined, data: DataType | null | undefined) => {
        if (data) return resolve({ type: 'success', data });

        error ??= new Error(unknownErrorMessage);
        resolve({ type: 'failure', error });
    };
}

type CognitoUserSessionResult = NodeCallbackResult<CognitoUserSession>;
type ConfirmRegistrationResult = NodeCallbackResult<unknown, unknown>;
type GetUserAttributesResult = NodeCallbackResult<CognitoUserAttribute[]>;
type ResendConfirmationCodeResult = NodeCallbackResult;

type NodeCallbackResult<Data = unknown, ErrorType = Error> =
    | NamedResult<'success', { data: Data }>
    | NamedResult<'failure', { error: ErrorType | Error }>;

type NamedResult<Type extends string, Data> = { type: Type } & Data;
