import { Observable, Subject } from 'rxjs';
import { NgZone } from '@angular/core';

/**
 * Observables originating from RxDB appear to not respect Angular zones.
 * This method patches that oversight.
 *
 * Applying this at the boundary of data coming from the database service
 * means that individual components don't have to worry about it.
 *
 * @deprecated Todo: This is a patch we would like to remove after the switch to signals.
 * @param source The observable which is ultimately originating from RxDB.
 * @param zone The Angular Zone in which we want the next file to run.
 */
export function patchRxdbZoneIssue<Value>(
    source: Observable<Value>,
    zone: NgZone,
): Observable<Value> {
    const copy = new Subject<Value>();
    const runInZone = (value: Value) => zone.run(() => copy.next(value));

    source.subscribe(runInZone);

    return copy;
}
