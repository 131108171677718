import { Environment } from './environment.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment: Environment = {
    serviceWorkerEnabled: true,
    graphqlDomain: 'https://api.dev.didit.co',
    appDomain: 'https://app.dev.didit.co',
    awsAuthentication: {
        poolData: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            UserPoolId: 'us-west-2_MCL52Eb38',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ClientId: '1b3pv73mtt453e1fvpg94du4k4',
        },
    },
    stripe: {
        publishableKey:
            // TODO: Replace with your own publishable key, this is sandbox key
            'pk_test_51QAIRb4Pjbst04L3M7OQ9DKmoGrXik2An8pijzGhMBK4pQCjUsE5b57g244EEWvzzJ8oQawGpaHkjTTF5F3Sc4vn00PirW4MNg',
    },
};
