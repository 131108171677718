import { Format, JsonSchema, Optional, Required } from 'ts-decorator-json-schema-generator';
import { Email, Uuid } from '@lib/shared-interface-utility-types';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { UuidFormat } from '../../shared/uuid-format.decorator';

@JsonSchema({
    title: 'Account Reference Schema',
    description: 'A reference to a user account with additional authentication information.',
})
class AccountReferenceSchemaGenerator implements AccountReference {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Required()
    @UuidFormat()
    public readonly authenticationId!: Uuid;

    @Optional()
    public readonly label?: string;

    @Optional()
    public readonly firstName?: string;

    @Optional()
    public readonly lastName?: string;

    @Optional()
    public readonly avatar?: string;

    @Required()
    @Format('email')
    public readonly email!: Email;
}

export const ACCOUNT_REFERENCE_SCHEMA = generateRxdbSchema(AccountReferenceSchemaGenerator, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
