export {};

// The following is necessary for the addRxPlugin function to work correctly
// with some plugins in an Angular environment
// https://rxdb.info/install.html#polyfill-the-global-variable
declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        process: any;
    }
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
(window as any).global = window;
window.process = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    env: { DEBUG: undefined },
};
