import { gql } from 'graphql-tag';
import { Inject, Injectable } from '@angular/core';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { Uuid } from '@lib/shared-interface-utility-types';
import { GraphqlMutation } from './graphql-mutation';

@Injectable({ providedIn: 'root' })
export class UpdateAccountEmailGql extends GraphqlMutation<undefined, Result> {
    public readonly document = gql`
        mutation UpdateAccountEmail {
            updateAccountEmail {
                id
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Result = Record<'updateAccountEmail', { id: Uuid }>;
