import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import {
    ReplicationCheckpoint,
    ReplicationDeletable,
} from '@lib/shared-interface-rxdb-replication-types';
import { AccountUse } from './account-enums.interface';
import { PaymentGatewayName } from '@lib/shared-interface-payment-types';

/**
 * A user account as represented server-side.
 *
 * An account may exist with only an ID and email address for an invited user.
 */
export type FullAccount = ReplicatedAccount & {
    /**
     * Unique ID in the authentication system.
     */
    authenticationId?: Uuid;
    /**
     * Date/time when the account accepted terms and conditions.
     */
    agreedToTermsAndConditionsAt?: number;
    /**
     * Date/time when the account accepted privacy policy.
     */
    acknowledgedPrivacyPolicyAt?: number;
    /**
     * All payment method data with various payment gateways
     * that have been saved by this account holder.
     */
    paymentInformation?: PaymentInformation;
};

/**
 * A customer's payment information with a specific gateway.
 */
export type PaymentInformation = {
    /**
     * The gateway that this payment information is associated with.
     */
    gateway: PaymentGatewayName;
    /**
     * The ID of the payer in the gateway's system.
     */
    payerId: string;
    /**
     * The ID of the payer's subscription in the gateway's system.
     */
    subscriptionId?: string;
    /**
     * The end date of the subscription.
     */
    validUntil?: number;
};

export type ReplicatedAccount = Account & ReplicationDeletable;
export type AccountReplicationCheckpoint = Pick<ReplicationCheckpoint, 'updatedAt'>;

/**
 * Contains properties of a user account shared between the client and server.
 *
 * There may be additional fields on the server side.
 */
export type Account = {
    /**
     * Unique ID for this account.
     */
    id: Uuid;
    /**
     * Label for the account.
     *
     * This can help to distinguish this account from other accounts managed by a single user.
     */
    label?: string;
    /**
     * The given name so that we know how to refer to the person behind this account.
     */
    firstName?: string;
    /**
     * The Family name so that we know how to refer to the person behind this account.
     */
    lastName?: string;
    /**
     * The email so that others can reach this account.
     */
    email: Email;
    /**
     * Phone number in E.164 format.
     */
    phone?: Phone;
    /**
     * Date of birth so that we know the age of whomever is behind this account.
     */
    dateOfBirth?: DateOfBirth;
    /**
     * The stated use of the account.
     */
    use?: AccountUse;
    /**
     * The ISO-3166 Alpha 2 country code for the home country.
     */
    homeCountryCode?: string;
    /**
     * The ISO-639-1 two-letter language code for the preferred language.
     */
    languageCode?: string;
    /**
     * The base64 encoded image uploaded by the user
     */
    avatar?: string;
    /**
     * Id of the plan in the Didit database
     */
    subscriberPlanId?: Uuid;
    /**
     * When the account data was last updated.
     * This is necessary for replication.
     */
    updatedAt: number;
};

/**
 * Internal structure used to store a user's DateOfBirth.
 */
export type DateOfBirth = {
    /**
     * Year should be all 4 digits (full year)
     */
    year: number;
    /**
     * Month should be a value 1 - 12 (not 0 - 11)
     */
    month: number;
    /**
     * Day should be a value 1 - 31 (not 0 - 30)
     */
    day?: number;
};

/**
 * Basic account info which is required to be considered registered.
 *
 * i.e. without a "label" and "firstName" or "lastName" and agreeing to terms and conditions
 * and privacy policy, account cannot yet use the main features of Didit.
 */
export type BasicAccountInfo = Required<Pick<FullAccount, 'firstName' | 'lastName'>> &
    Pick<FullAccount, 'phone' | 'acknowledgedPrivacyPolicyAt' | 'agreedToTermsAndConditionsAt'>;

/*
 * The Plan Id the user selects when they sign up for an account
 * */
export type AccountPlanInfo = Pick<Account, 'subscriberPlanId'> & {
    renewalStrategy: string;
};

export type AccountUpdate = Partial<
    Omit<Account, 'id' | 'updatedAt' | 'email' | 'subscriberPlanId'>
>;
