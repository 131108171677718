import { InjectionToken, Provider } from '@angular/core';

export const COUNTRY_FLAG_ICON_PATH_TOKEN = new InjectionToken<string>('Flag SVG Path');

export function provideCountryFlagIconPath(flagSvgPath: string): Provider {
    return {
        provide: COUNTRY_FLAG_ICON_PATH_TOKEN,
        useValue: flagSvgPath,
    };
}
