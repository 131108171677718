import { Uuid } from '@lib/shared-interface-utility-types';
import { ACCOUNT_SCHEMA } from './account-collection/account.schema';
import { Account } from '@lib/shared-interface-account';
import { createRxDatabase, RxCollection, RxCollectionCreator, RxDatabase } from 'rxdb';
import { Ask } from '@lib/shared-interface-ask';
import { ASK_SCHEMA } from './asks-collection/ask.schema';
import { getCommonDatabaseOptions } from '../shared/get-common-database-options';
import { Contact } from '@lib/shared-interface-contact';
import { CONTACT_SCHEMA } from './contacts-collection/contact.schema';

/**
 * Ensure account databases are created only once.
 * @param id
 */
export function getAccountDatabase(id: Uuid): Promise<AccountDatabase> {
    // Database exists, don't recreate it
    let database = databaseCache.get(id);
    if (database != undefined) return database;

    // Database doesn't yet exist, go ahead and create it.
    database = createAccountDatabase(id);
    databaseCache.set(id, database);

    return database;
}

// We keep track of database connections to avoid creating them more than once
const databaseCache = new Map<Uuid, Promise<AccountDatabase>>();

async function createAccountDatabase(id: Uuid): Promise<AccountDatabase> {
    const name = 'account_' + id;
    const commonOptions = getCommonDatabaseOptions();
    const database = await createRxDatabase<AccountCollections>({ ...commonOptions, name });

    await database.addCollections<AccountCollections>(collectionCreators);

    database.account.preSave(attachUpdatedAt, true);
    database.asks.preSave(attachUpdatedAt, true);
    database.contacts.preSave(attachUpdatedAt, true);

    return database;
}

const collectionCreators = {
    /* eslint-disable @typescript-eslint/naming-convention */
    account: { schema: ACCOUNT_SCHEMA } satisfies RxCollectionCreator<Account>,
    asks: { schema: ASK_SCHEMA } satisfies RxCollectionCreator<Ask>,
    contacts: { schema: CONTACT_SCHEMA } satisfies RxCollectionCreator<Contact>,
    /* eslint-enable @typescript-eslint/naming-convention */
} satisfies Record<keyof AccountCollections, RxCollectionCreator>;

function attachUpdatedAt<RxdbDocument extends { updatedAt: number }>(document: RxdbDocument) {
    document.updatedAt = Date.now();
}

export type AccountDatabase = RxDatabase<AccountCollections>;

/**
 * Specifies the collections on the account database.
 *
 * Note that `camelCase` cannot be used for collection names in RxDB; we use `snake_case` instead.
 */
export interface AccountCollections {
    /* eslint-disable @typescript-eslint/naming-convention */
    account: AccountCollection;
    asks: AsksCollection;
    contacts: ContactsCollection;
    /* eslint-enable @typescript-eslint/naming-convention */
}

export type AccountCollection = RxCollection<Account>;
export type AsksCollection = RxCollection<Ask>;
export type ContactsCollection = RxCollection<Contact>;
