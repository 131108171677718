<lib-branded-navigation #navigation>
    <!-- Without tabindex="" this requires two tabs to actually focus the correct element -->
    <ion-button
        desktopNavigationLink
        routerLink="/todo"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.todo' | translate }}</ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/watching"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.watching' | translate }} </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/done"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.done' | translate }} </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/explore"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.explore' | translate }} </ion-label>
    </ion-button>

    <ion-tab-button mobileTabButton tab="todo">
        <ion-label>
            {{ 'navigation.primary.todo' | translate }}
        </ion-label>
        <ion-icon src="/assets/icons/checklist.svg" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="watching">
        <ion-label>
            {{ 'navigation.primary.watching' | translate }}
        </ion-label>
        <ion-icon src="/assets/icons/eyeglasses.svg" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="dashboard">
        <picture>
            <img alt="didit logo" class="logo-icon" src="/assets/logos/logo-icon-only.svg" />
        </picture>
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="done">
        <ion-label>
            {{ 'navigation.primary.done' | translate }}
        </ion-label>
        <ion-icon name="checkmark-circle-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="explore">
        <ion-label>
            {{ 'navigation.primary.explore' | translate }}
        </ion-label>
        <ion-icon name="compass-outline" />
    </ion-tab-button>

    <ion-list sideMenuContent lines="none" class="no-safe-area-right">
        <ion-item-group>
            <ion-item-divider>
                <ion-label> App</ion-label>
            </ion-item-divider>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/contacts"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.contacts' | translate }} </ion-label>
                <ion-icon name="people-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/drafts"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label>Drafts</ion-label>
                <ion-icon name="document-text-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/trash"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label>Trash</ion-label>
                <ion-icon name="trash-outline" slot="start" />
            </ion-item>
        </ion-item-group>
        <ion-item-group>
            <ion-item-divider>
                <ion-label> Information</ion-label>
            </ion-item-divider>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/usage"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.howToUseDidIt' | translate }} </ion-label>
                <ion-icon name="help-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/feedback"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.feedback' | translate }} </ion-label>
                <ion-icon name="chatbox-ellipses-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/whats-new"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.whatsNew' | translate }} </ion-label>
                <ion-icon name="newspaper-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/share"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.tellAFriend' | translate }} </ion-label>
                <ion-icon name="chatbubbles-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/about"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.about' | translate }} </ion-label>
                <ion-icon name="information-circle-outline" slot="start" />
            </ion-item>
            <ion-item
                (click)="navigation.closeWhenSmall()"
                button
                detail="false"
                routerLink="/information/privacy-policy"
                routerLinkActive="selected"
                routerDirection="root"
            >
                <ion-label> {{ 'navigation.mobile.privacyPolicy' | translate }} </ion-label>
                <ion-icon name="ear-outline" slot="start" />
            </ion-item>
        </ion-item-group>
    </ion-list>

    @if (account(); as account) {
        <div class="stacked-icon-button" accountMenuToggle>
            <ion-button>
                <lib-account-avatar slot="icon-only" [account]="account" [width]="28" />
            </ion-button>
            <ion-label color="medium" position="bottom"> {{ account.label }} </ion-label>
        </div>
    } @else {
        <!-- Technically, if you see this, there is an issue.
             The reason is that we shouldn't see a navigation if there is no active account.
             However, it should stay in here for state-debugging purposes. -->
        <ion-button accountMenuToggle>
            <ion-avatar slot="icon-only">
                <ion-icon name="person-circle-outline" color="medium" />
            </ion-avatar>
        </ion-button>
    }

    <div accountMenuToolbarContent class="active-account-menu-header ion-padding">
        @if (activeAccountReference$ | async; as accountReference) {
            <lib-account-avatar
                class="active-account-avatar"
                [width]="48"
                fontSize="2rem"
                [account]="accountReference"
            />
            <div>
                <div class="active-account-label">
                    {{ accountReference.label }}
                </div>
                <div>
                    <small class="active-account-full-name">
                        {{ accountReference.firstName }} {{ accountReference.lastName }}
                        @if (offline$ | async) {
                            <span> - Offline</span>
                        }
                    </small>
                </div>
            </div>
        }
    </div>

    @if (activeAccountReference$ | async; as accountReference) {
        <ion-list accountMenuContent lines="none" class="ion-no-padding">
            <ion-menu-toggle menu="account-menu">
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/details"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/account_circle.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.profile' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/preferences"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/manage_accounts.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.preferences' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/manage"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/dashboard.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.myPlan' | translate }}
                    </ion-label>
                </ion-item>
                @if (accountPlan() === 'BASIC') {
                    <ion-item
                        button
                        detail="false"
                        routerLink="/account/subscription"
                        routerLinkActive="selected"
                        routerDirection="root"
                    >
                        <ion-icon
                            class="plans-icon"
                            src="assets/icons/plan-selection.svg"
                            slot="end"
                        />
                        <ion-label>
                            {{ 'navigation.account.upgrade' | translate }}
                        </ion-label>
                    </ion-item>
                }
                @if (!accountReference.isLoggedIn) {
                    <ion-item
                        [disabled]="offline$ | async"
                        [queryParams]="{ email: accountReference.email }"
                        button
                        detail="false"
                        routerDirection="root"
                        routerLink="/account/login"
                        routerLinkActive="selected"
                    >
                        <ion-icon src="assets/icons/login.svg" slot="end" />
                        <ion-label> {{ 'navigation.account.logIn' | translate }} </ion-label>
                    </ion-item>
                } @else {
                    <!-- Todo: click handler to log out selected account -->
                    <ion-item
                        (click)="logOutAllAccounts()"
                        [disabled]="offline$ | async"
                        button
                        detail="false"
                    >
                        <ion-icon src="assets/icons/logout.svg" slot="end" />
                        <ion-label> {{ 'navigation.account.logOut' | translate }} </ion-label>
                    </ion-item>
                }
            </ion-menu-toggle>
        </ion-list>
    }
    <ion-item-group accountMenuContent class="menu-light-contrast">
        <ion-item-divider>
            <ion-label> Other Accounts </ion-label>
        </ion-item-divider>

        @for (accountReference of inactiveAccountReferences$ | async; track accountReference.id) {
            <ion-item
                (click)="setActiveAccount(accountReference.id)"
                button
                class="menu-light-contrast"
                detail="false"
                lines="none"
            >
                <lib-account-avatar
                    [account]="accountReference"
                    [width]="28"
                    fontSize=".7rem"
                    slot="start"
                />
                <ion-label>
                    {{ accountReference.label ?? accountReference.email }}
                </ion-label>
                @if (!accountReference.isLoggedIn) {
                    <ion-text slot="end" color="medium">
                        <small>Logged Out</small>
                    </ion-text>
                }
            </ion-item>
        }
        <ion-menu-toggle menu="account-menu">
            <ion-item
                button
                class="menu-light-contrast"
                detail="false"
                lines="none"
                routerLink="/accounts/manage"
            >
                <ion-label>
                    {{ 'navigation.account.manageAccounts' | translate }}
                </ion-label>
                <ion-icon name="people-outline" slot="end" />
            </ion-item>
        </ion-menu-toggle>
    </ion-item-group>
</lib-branded-navigation>
