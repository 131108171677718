import {
    CommonMissingTranslationHandler,
    httpLoaderFactory,
    I18N_APP_INITIALIZER,
} from '@lib/didit-accounts-i18n-service';
import {
    PreloadAllModules,
    provideRouter,
    RouteReuseStrategy,
    withEnabledBlockingInitialNavigation,
    withPreloading,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { TITLE_STRATEGY_PROVIDER } from './providers/title-strategy-provider';
import { APP_ROUTES } from './app.routes';
import { environment } from '../environments/environment';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAuthenticationConfiguration } from '@lib/didit-authentication-data-authentication-service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStripeConfiguration } from '@lib/didit-payments-feature-stripe-payment-form';
import { OnlineStatusService } from '@lib/didit-shared-data-online-status-service';
import { provideInterceptor } from '@lib/didit-shared-util-miscellaneous-providers';
import { AuthenticationInterceptor } from '@lib/didit-authentication-data-authentication-interceptor';
import { provideGraphqlConfiguration } from '@lib/didit-authentication-graphql-client';
import { ACTIVE_ACCOUNT_ID_INITIALIZER } from './providers/active-account-id-initializer';
import { provideCountryFlagIconPath } from '@lib/shared-ui-ionic-phone-number-control';
import { provideAppUpdateInitializer } from '@lib/didit-shared-util-app-update-service';
import { provideClientAppDomainConfiguration } from '@lib/didit-payments-data-payment-processor';

const mobilePattern = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
const isMobile = (window: Window) => mobilePattern.test(window.navigator.userAgent);
const isDesktop = (window: Window) => !isMobile(window);

export const APP_CONFIG: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            // Todo: upgrade ngx-translate and maybe we won't have to use `importProvidersFrom`.
            TranslateModule.forRoot({
                missingTranslationHandler: {
                    provide: MissingTranslationHandler,
                    useClass: CommonMissingTranslationHandler,
                },
                loader: {
                    provide: TranslateLoader,
                    useFactory: httpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),

        // Updated way to provide BrowserAnimationsModule
        provideAnimations(),
        provideAppUpdateInitializer(),
        provideAuthenticationConfiguration(environment.awsAuthentication.poolData),
        provideInterceptor(AuthenticationInterceptor),
        provideCountryFlagIconPath('/assets/icons/flags'),
        provideGraphqlConfiguration(environment.graphqlDomain),
        provideClientAppDomainConfiguration(environment.appDomain),

        // TODO: Figure out how to make this a functional interceptor.
        // DI interceptors may be phased out in the future.
        // (https://angular.io/api/common/http/withInterceptorsFromDi#description)
        // But this interceptor relies on services provided by DI.
        // This gives some hints: https://dev.to/this-is-angular/angular-15-interceptor-as-a-function-2g44
        provideHttpClient(withInterceptorsFromDi()),

        // Adapted from a fresh Ionic 8 Angular project.
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideIonicAngular({
            // We are locking it down to one style to minimize necessary design decisions.
            // If you need md mode on an ionic component, use set `mode="md"` on said component.
            mode: 'md',
            platform: {
                /**
                 * For devices with a touchscreen the default `desktop` function returns false
                 * and the default `mobile` function return true.
                 * This is not always wanted, so we've overwritten these to use the user agent.
                 * https://ionicframework.com/docs/angular/platform#customizing-platform-detection-functions.
                 **/
                desktop: isDesktop,
                mobile: isMobile,
            },
        }),

        // This is how we import the root level routes.
        // https://angular.io/guide/standalone-components#configuring-dependency-injection
        provideRouter(
            APP_ROUTES,
            withEnabledBlockingInitialNavigation(),
            withPreloading(PreloadAllModules),
        ),
        provideServiceWorker('ngsw-worker.js', {
            enabled: environment.serviceWorkerEnabled,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        provideStripeConfiguration(environment.stripe),

        // These are traditional root-level providers
        ACTIVE_ACCOUNT_ID_INITIALIZER,
        I18N_APP_INITIALIZER,
        OnlineStatusService,
        TITLE_STRATEGY_PROVIDER,
    ],
};
