import * as uuid from 'uuid';
import { Ask, AskStatus } from '@lib/shared-interface-ask';

/**
 * Minimal input required to create an ask.
 */
export type CreateAskInput = Omit<
    Ask,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'trashedAt'
    | 'completedAt'
    | 'statusChanges'
    | 'currentStatus'
>;

export function createRxdbAskInsert(input: CreateAskInput): Ask {
    const id = uuid.v4();
    const createdAt = Date.now();
    const updatedAt = createdAt;
    const statusChanges = [{ timestamp: createdAt, status: AskStatus.DRAFT }];
    const currentStatus = AskStatus.DRAFT;
    const recipients = input.recipients ?? [];

    return {
        id,
        ...input,
        currentStatus,
        createdAt,
        updatedAt,
        statusChanges,
        recipients,
    };
}
