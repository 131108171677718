import { Route } from '@angular/router';
import { NavigationComponent } from '@lib/didit-navigation-feature-navigation';
import {
    createHasCompletedRegistrationGuard,
    createHasIncompleteRegistrationGuard,
    createIsLoggedInGuard,
} from '@lib/didit-accounts-data-accounts-service';

const isLoggedIn = createIsLoggedInGuard('/account/login');
const hasCompletedRegistration = createHasCompletedRegistrationGuard('/account/registration');
const hasIncompleteRegistration = createHasIncompleteRegistrationGuard('/dashboard');

export const APP_ROUTES: Route[] = [
    {
        path: '',
        component: NavigationComponent,
        canActivate: [isLoggedIn, hasCompletedRegistration],
        children: [
            {
                path: 'watching',
                title: 'Watching',
                loadComponent: () => import('@lib/didit-asks-feature-watching-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'todo',
                title: 'To Do',
                loadComponent: () => import('@lib/didit-asks-feature-to-do-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'drafts',
                title: 'Drafts',
                loadComponent: () => import('@lib/didit-asks-feature-drafts-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'trash',
                title: 'Trash',
                loadComponent: () => import('@lib/didit-asks-feature-trash-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'done',
                title: 'Done',
                loadComponent: () => import('@lib/didit-asks-feature-done-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'explore',
                title: 'Explore',
                loadComponent: () => import('@lib/didit-asks-feature-explore-page'),
            },
            {
                path: 'account/details',
                title: 'Account',
                loadComponent: () => import('@lib/didit-accounts-feature-edit-account-page'),
            },
            {
                path: 'account/preferences',
                title: 'Account Preferences',
                loadComponent: () => import('@lib/didit-accounts-feature-preferences-page'),
            },
            {
                path: 'account/manage',
                title: 'My Plan',
                loadComponent: () => import('@lib/didit-accounts-feature-manage-account-page'),
            },
            {
                path: 'account/billing-history',
                title: 'Billing History',
                loadComponent: () => import('@lib/didit-accounts-feature-billing-page'),
            },
            {
                path: 'account/subscription',
                title: 'Subscription',
                loadComponent: () =>
                    import('@lib/didit-accounts-feature-account-subscription-page'),
            },
            {
                path: 'accounts/manage',
                title: 'Manage Accounts',
                loadComponent: () => import('@lib/didit-accounts-feature-manage-accounts-page'),
            },
            {
                path: 'contacts',
                title: 'Contacts',
                loadComponent: () => import('@lib/didit-contacts-feature-manage-contacts-page'),
                data: { hideTabNavigation: { ifQueryParameter: 'id' } },
            },
            {
                path: 'information/terms-and-conditions',
                title: 'Terms and Conditions',
                loadComponent: () =>
                    import('@lib/didit-information-feature-terms-and-conditions-page'),
            },
            {
                path: 'information/privacy-policy',
                title: 'Privacy Policy',
                loadComponent: () => import('@lib/didit-information-feature-privacy-policy-page'),
            },
            {
                path: 'information/about',
                title: 'About',
                loadComponent: () => import('@lib/didit-information-feature-about-page'),
            },
            {
                path: 'information/feedback',
                title: 'Feedback',
                loadComponent: () => import('@lib/didit-information-feature-feedback-page'),
            },
            {
                path: 'information/getting-started',
                title: 'Getting Started',
                loadComponent: () => import('@lib/didit-information-feature-getting-started-page'),
            },
            {
                path: 'information/share',
                title: 'Tell a Friend',
                loadComponent: () => import('@lib/didit-information-feature-share-page'),
            },
            {
                path: 'information/usage',
                title: 'Usage',
                loadComponent: () => import('@lib/didit-information-feature-usage-page'),
            },
            {
                path: 'information/whats-new',
                title: "What's New",
                loadComponent: () => import('@lib/didit-information-feature-whats-new-page'),
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                loadComponent: () => import('@lib/didit-asks-feature-dashboard-page'),
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'account/login',
        title: 'Log In',
        loadComponent: () => import('@lib/didit-authentication-feature-login-page'),
    },
    {
        path: 'account/register',
        title: 'Account Registration',
        loadComponent: () => import('@lib/didit-authentication-feature-register-page'),
    },
    {
        path: 'account/confirm-registration',
        redirectTo: 'account/register',
        pathMatch: 'full',
    },
    {
        path: 'account/confirm-registration/:username',
        title: 'Confirm Registration',
        loadComponent: () => import('@lib/didit-authentication-feature-confirm-registration-page'),
    },
    {
        path: 'account/registration',
        title: 'Registration',
        loadComponent: () => import('@lib/didit-authentication-feature-basic-information-page'),
        canActivate: [isLoggedIn, hasIncompleteRegistration],
    },
    {
        path: 'account/reset-password',
        title: 'Password Reset',
        loadComponent: () => import('@lib/didit-authentication-feature-reset-password-page'),
    },
    {
        path: 'account/change-password',
        redirectTo: 'account/reset-password',
        pathMatch: 'full',
    },
    {
        path: 'account/change-password/:email',
        title: 'Change Password',
        loadComponent: () => import('@lib/didit-authentication-feature-change-password-page'),
    },
];
