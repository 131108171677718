import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';
import { Provider, Type } from '@angular/core';

export function provideInterceptor(interceptorClass: Type<HttpInterceptor>): Provider {
    return {
        provide: HTTP_INTERCEPTORS,
        useClass: interceptorClass,
        multi: true,
    };
}
