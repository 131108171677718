import {
    Default,
    Enum,
    Items,
    JsonSchema,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import {
    SubscriberPlan,
    SubscriberPlanPrice,
    SubscriberPlanType,
} from '@lib/shared-interface-subscriber-plan';
import { SubscriberPlanPriceSchema } from './subscriber-plan-price.schema';

@JsonSchema({
    title: 'Subscriber Plan Schema',
    description: 'A plan that a subscriber can subscribe to.',
})
class SubscriberPlanSchemaGenerator implements SubscriberPlan {
    @Required()
    @UuidFormat()
    public readonly id!: string;

    @Required()
    public readonly description!: string;

    @Required()
    public readonly title!: string;

    @Required()
    @Items(SubscriberPlanPriceSchema)
    @Default([])
    public readonly pricing!: SubscriberPlanPrice[];

    @Required()
    @Enum(SubscriberPlanType)
    public readonly type!: SubscriberPlanType;

    @Required()
    public readonly default!: boolean;

    @Required()
    public readonly order!: number;

    @Required()
    @Type('integer')
    public readonly createdAt!: number;

    @Required()
    @Type('integer')
    public readonly updatedAt!: number;
}

export const SUBSCRIBER_PLAN_SCHEMA = generateRxdbSchema(SubscriberPlanSchemaGenerator, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
