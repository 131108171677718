import { Uuid } from '@lib/shared-interface-utility-types';
import { RenewalStrategy, SubscriberPlanType } from './subscriber-plan-enums.interface';
import { PaymentGatewayName } from '@lib/shared-interface-payment-types';
import { ReplicationDeletable } from '@lib/shared-interface-rxdb-replication-types';

export type FullSubscriberPlan = ReplicatedSubscriberPlan & {
    pricing: FullSubscriberPlanPrice[];

    /**
     * Is this plan currently being offered to new users?
     */
    active: boolean;
};

export type ReplicatedSubscriberPlan = SubscriberPlan & ReplicationDeletable;

/**
 * Contains properties of a plan shared between the client and server.
 */
export type SubscriberPlan = {
    /**
     * Unique ID for this plan.
     */
    id: Uuid;

    /**
     * The title of the plan.
     */
    title: string;

    /**
     * A short description of the plan that will be shown to subscribing users.
     */
    description: string;

    /**
     * Different pricing schemes that users can choose from to pay for this plan.
     */
    pricing: SubscriberPlanPrice[];

    /**
     * The plan type ties this plan into business logic based around the plan
     */
    type: SubscriberPlanType;

    /**
     * Is this the default plan that should be selected when users register?
     */
    default: boolean;

    /**
     * The order in which this plan should be displayed to users.
     */
    order: number;

    /**
     * The last time this plan was updated.
     */
    createdAt: number;

    /**
     * The last time this plan was updated.
     */
    updatedAt: number;
};

export type FullSubscriberPlanPrice = SubscriberPlanPrice & {
    /**
     * An array of payment gateways and their corresponding price ID for this price.
     */
    gatewayPriceRecords?: GatewayPriceRecord[];
};

export type SubscriberPlanPrice = {
    /**
     * Unique id of this SubscriberPlanPrice subdocument.
     */
    id: Uuid;

    /**
     * How often the plan is renewed.
     */
    renewalStrategy: RenewalStrategy;

    /**
     * The price of the plan for each period indicated by the renewalStrategy.
     */
    price: number;
};

export type GatewayPriceRecord = {
    /**
     * The name of the payment gateway.
     */
    gateway: PaymentGatewayName;

    /**
     * The price ID for this price on the payment gateway.
     */
    priceId: string;

    /**
     * The ID of the plan in the payment gateway's system.
     */
    gatewayPlanId: string;

    /**
     * Whether this price is currently active.
     */
    active: boolean;
};
