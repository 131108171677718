import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonToolbar,
    ModalController,
} from '@ionic/angular/standalone';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js/min';

import { COUNTRY_FLAG_ICON_PATH_TOKEN } from '../provide-country-flag-icon-path';
import { getCountryFlagIcon } from '../pipes/get-country-flag-icon';

@Component({
    standalone: true,
    selector: 'lib-phone-number-country-list-modal',
    templateUrl: './phone-number-country-list.modal.html',
    styleUrls: ['./phone-number-country-list.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem],
})
export class PhoneNumberCountryListModal implements OnInit {
    @Input() public locale = '';
    protected countries!: CountryDefinition[];
    private regionNameTransformer!: Intl.DisplayNames;

    public constructor(
        @Inject(COUNTRY_FLAG_ICON_PATH_TOKEN) private readonly flagIconPath: string,
        private readonly modalController: ModalController,
    ) {}

    public ngOnInit(): void {
        this.regionNameTransformer = new Intl.DisplayNames([this.locale], {
            type: 'region',
            fallback: 'none',
        });
        this.countries = this.getSortedCountryDefinitions();
    }

    public async closeModal(countryDefinition?: CountryDefinition): Promise<void> {
        await this.modalController.dismiss(
            countryDefinition?.code,
            countryDefinition ? 'confirm' : 'cancel',
        );
    }

    private buildCountryDefinition(countryCode: CountryCode): CountryDefinition {
        let callingCode: string | undefined;
        let fullName: string | undefined;

        try {
            callingCode = `+${getCountryCallingCode(countryCode)}`;
        } catch {
            callingCode = '';
        }

        try {
            fullName = this.regionNameTransformer.of(countryCode);
        } catch {
            fullName = undefined;
        }

        return {
            code: countryCode,
            fullName: fullName,
            flagUrl: getCountryFlagIcon(this.flagIconPath, countryCode),
            callingCode: callingCode,
        };
    }

    private getSortedCountryDefinitions(): CountryDefinition[] {
        const buildDefinition = (code: CountryCode) => this.buildCountryDefinition(code);
        return getCountries().map(buildDefinition).sort(sortTwoCountriesByFullName);
    }
}

function sortTwoCountriesByFullName(
    thisCountry: CountryDefinition,
    nextCountry: CountryDefinition,
): number {
    const thisFullName = thisCountry.fullName?.toUpperCase();
    const nextFullName = nextCountry.fullName?.toUpperCase();
    if (!thisFullName) {
        return 1;
    } else if (!nextFullName) {
        return -1;
    }
    return thisFullName.localeCompare(nextFullName);
}

interface CountryDefinition {
    code: CountryCode;
    fullName: string | undefined;
    flagUrl: string;
    callingCode: string;
}
