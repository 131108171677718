import { InjectionToken, Provider } from '@angular/core';

export type GraphqlDomain = string;
export const GRAPHQL_DOMAIN_URL_TOKEN = new InjectionToken<string>('GraphqlDomain');

export function provideGraphqlConfiguration(url: string): Provider[] {
    const configurationProvider = {
        provide: GRAPHQL_DOMAIN_URL_TOKEN,
        useValue: url,
    };
    return [configurationProvider];
}
