import { getAppDatabase } from './app-database/get-app-database';
import { BehaviorSubject, filter } from 'rxjs';
import { Account } from '@lib/shared-interface-account';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { getAccountDatabase } from './account-database/get-account-database';
import { Uuid } from '@lib/shared-interface-utility-types';
import { MangoQueryNoLimit, RxChangeEvent } from 'rxdb';
import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    startAccountDatabaseReplication,
    stopAccountDatabaseReplication,
} from './account-database/account-database-replication-helpers';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '@lib/didit-authentication-graphql-client';
import {
    CommonSyncOptions,
    startAppDatabaseReplication,
    stopAppDatabaseReplication,
} from './app-database/app-database-replication-helpers';
import { AccountDatabaseJanitor } from './account-database/account-database-janitor';
import { Duration } from 'luxon';
import { patchRxdbZoneIssue } from './shared/patch-rxdb-zone-issue';

const cleanupFrequency = Duration.fromObject({ day: 1 }).as('millisecond');
const maxAge = Duration.fromObject({ month: 1 }).as('millisecond');

@Injectable({ providedIn: 'root' })
export class DatabaseService {
    // Making this a signal allows us to externally reconfigure these values as needed later on.
    // Todo: we need to determine if these options will be configured at the account level
    //  or at the application level.
    private readonly trashPolicy = new BehaviorSubject({ cleanupFrequency, maxAge });

    public constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) private graphqlDomain: GraphqlDomain,
        private readonly zone: NgZone,
    ) {
        // If the below logic makes it difficult to test,
        // we can move it to a non-blocking `APP_INITIALIZER`.
        void this.connectToExistingAccounts();
        void this.listenAndConnectToNewAccounts();
    }

    public async startAppDatabaseReplication(): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
        };

        // Pull up the app database.
        const database = await getAppDatabase();

        startAppDatabaseReplication(database, commonOptions);
    }

    public stopAppDatabaseReplication() {
        return stopAppDatabaseReplication();
    }

    public async startAccountDatabaseReplication(accountId: Uuid, token: string): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
            headers: {
                authorization: token,
            },
        };

        // Pull up account database.
        const database = await getAccountDatabase(accountId);

        startAccountDatabaseReplication(accountId, database, commonOptions);
    }

    public stopAccountDatabaseReplication(accountId: Uuid) {
        return stopAccountDatabaseReplication(accountId);
    }

    private async connectAccountDatabase(id: Uuid): Promise<void> {
        const accountDatabase = await getAccountDatabase(id);

        // Listen for changes to the actual account data.
        // If duplicate data changes, patch the account reference.
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { account_references } = await getAppDatabase();
        const selectById: MangoQueryNoLimit<AccountReference> = {
            selector: { id },
        };
        const query = account_references.findOne(selectById);
        const accountReference = await query.exec();

        // This is mainly type-guarding; this should never happen.
        if (accountReference == undefined) return;

        const updateAccountReference = (changeEvent: RxChangeEvent<Account>) => {
            const { label, email, firstName, lastName, avatar } = changeEvent.documentData;
            const patchData = { label, email, firstName, lastName, avatar };
            const latestAccountReference = accountReference.getLatest();

            if (changeEvent.operation === 'DELETE') {
                void latestAccountReference.incrementalRemove();
                return;
            }
            void latestAccountReference.incrementalPatch(patchData);
        };

        // If the account changes and the data duplicated on an account reference is changed,
        // then we get that duplicate data and patch the account reference with it.
        // This way, when syncing or updating locally,
        // the label/email in the account menu always stays up to date.
        const changes$ = patchRxdbZoneIssue(accountDatabase.account.$, this.zone);
        const duplicateDataUpdate$ = changes$.pipe(filter(isUpdateToAccountReference));

        duplicateDataUpdate$.subscribe(updateAccountReference);

        // Configuration beyond this point will only occur
        // if this account database is elected the leader.
        // This might never occur if this tab is closed before the current leader is closed.
        await accountDatabase.waitForLeadership();

        const janitor = AccountDatabaseJanitor.createFor(accountDatabase);

        // If we change the trash policy at runtime, such as with a change in account configuration,
        // this effect will re-run and re-start the maintenance cycle.
        this.trashPolicy.subscribe(({ cleanupFrequency, maxAge }) => {
            janitor.startMaintenance(cleanupFrequency, maxAge);
        });
    }

    private async connectToExistingAccounts(): Promise<void> {
        // This does not wait for initial database connections, it only initiates their connection.
        const appDatabase = await getAppDatabase();
        const query = appDatabase.account_references.find();
        const accountReferences = await query.exec();
        const connectDatabase = ({ id }: AccountReference) => this.connectAccountDatabase(id);

        void accountReferences.map(connectDatabase);
    }

    private async listenAndConnectToNewAccounts(): Promise<void> {
        // Listen for changes to account references
        // and decide to connect/disconnect the corresponding account database.
        const appDatabase = await getAppDatabase();
        const handleChangeEvent = (changeEvent: RxChangeEvent<AccountReference>) => {
            const { operation, documentData } = changeEvent;
            if (operation === 'INSERT') void this.connectAccountDatabase(documentData.id);
            // Todo: handle update/delete change events;
            //  e.g. when an account reference is deleted,
            //  do we disconnect or delete the associated account database?
            //  Maybe we eventually need a "logged in" property stored on an account reference?
        };

        const changes$ = patchRxdbZoneIssue(appDatabase.account_references.$, this.zone);
        changes$.subscribe(handleChangeEvent);
    }
}

function isUpdateToAccountReference(changeEvent: RxChangeEvent<Account>) {
    const { operation, previousDocumentData, documentData } = changeEvent;
    if (operation !== 'UPDATE') return true;

    return duplicatedDataChanged(previousDocumentData, documentData);
}

function duplicatedDataChanged(previous: DuplicatedAccountData, current: DuplicatedAccountData) {
    return (
        previous.label !== current.label ||
        previous.avatar !== current.avatar ||
        previous.email !== current.email ||
        previous.firstName !== current.firstName ||
        previous.lastName !== current.lastName
    );
}

type DuplicatedAccountData = Pick<
    AccountReference,
    'label' | 'email' | 'firstName' | 'lastName' | 'avatar'
>;
