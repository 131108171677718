import { Pipe, PipeTransform } from '@angular/core';
import { Phone } from '@lib/shared-interface-utility-types';
import { isValidPhoneNumber, NumberFormat, parsePhoneNumber } from 'libphonenumber-js/min';

@Pipe({
    name: 'libPhoneNumber',
    standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
    public transform(value?: Phone, numberFormat: NumberFormat = 'NATIONAL'): string {
        if (value == undefined) return '';
        return formatPhoneNumber(value, numberFormat);
    }
}

function formatPhoneNumber(value: Phone, numberFormat: NumberFormat): string {
    if (!isValidPhoneNumber(value)) return value;

    const phoneNumber = parsePhoneNumber(value);
    return phoneNumber.format(numberFormat);
}
