import { createRxDatabase, RxCollection, RxCollectionCreator, RxDatabase } from 'rxdb';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { ACCOUNT_REFERENCE_SCHEMA } from './account-references-collection/account-reference.schema';
import { getCommonDatabaseOptions } from '../shared/get-common-database-options';
import { SUBSCRIBER_PLAN_SCHEMA } from './subscriber-plans-collection/subscriber-plan.schema';
import { SubscriberPlan } from '@lib/shared-interface-subscriber-plan';

/**
 * Ensure the app database is created only once.
 */
export function getAppDatabase(): Promise<AppDatabase> {
    if (appDatabase != undefined) return appDatabase;

    appDatabase = createAppDatabase();

    return appDatabase;
}

let appDatabase: Promise<AppDatabase>;

async function createAppDatabase(): Promise<AppDatabase> {
    const name = 'application';
    const commonOptions = getCommonDatabaseOptions();
    const database = await createRxDatabase<AppCollections>({ name, ...commonOptions });

    await database.addCollections<AppCollections>(collectionCreators);

    return database;
}

const collectionCreators = {
    /* eslint-disable @typescript-eslint/naming-convention */
    account_references: {
        schema: ACCOUNT_REFERENCE_SCHEMA,
    } satisfies RxCollectionCreator<AccountReference>,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    subscriber_plans: {
        schema: SUBSCRIBER_PLAN_SCHEMA,
    } satisfies RxCollectionCreator<SubscriberPlan>,
    /* eslint-enable @typescript-eslint/naming-convention */
} satisfies Record<keyof AppCollections, RxCollectionCreator>;

export type AppDatabase = RxDatabase<AppCollections>;

/**
 * Specifies the collections on the app database.
 *
 * Note that `camelCase` cannot be used for collection names in RxDB; we use `snake_case` instead.
 */
export interface AppCollections {
    /* eslint-disable @typescript-eslint/naming-convention */
    account_references: AccountReferencesCollection;
    subscriber_plans: SubscriberPlanCollection;
    /* eslint-enable @typescript-eslint/naming-convention */
}

export type AccountReferencesCollection = RxCollection<AccountReference>;
export type SubscriberPlanCollection = RxCollection<SubscriberPlan>;
