import type { Observable } from 'rxjs';

/**
 * Return the immediate error from the provided observable.
 *
 * If there is no immediate error,
 * `isImmediate` will be false.
 *
 * @param observable
 */
export function getImmediateErrorFrom<ErrorType>(
    observable: Observable<unknown>,
): ImmediateErrorResult<ErrorType> {
    let result: ImmediateErrorResult<ErrorType> = { isImmediate: false };
    const handleError = (error: ErrorType) => (result = { isImmediate: true, error });
    const subscription = observable.subscribe({ error: handleError });

    subscription.unsubscribe();

    return result;
}
type ImmediateErrorResult<ErrorType> =
    | {
          isImmediate: true;
          error: ErrorType;
      }
    | {
          isImmediate: false;
      };
