import { Observable, startWith } from 'rxjs';

export function fromFormControl<Value>(control: Control<Value>): Observable<Value> {
    return control.valueChanges.pipe(startWith(control.value));
}

/**
 * A basic type to allow compatibility with Angular form controls
 * without relying on the Angular package directly.
 */
type Control<Value> = {
    readonly value: Value;
    readonly valueChanges: Observable<Value>;
};
