// If this isn't used, nulls can easily sneak into persistence.
// Ideally, the backend would protect from this as well.
export function removeNulls<Value extends object>(object: Value): Value {
    const entries = Object.entries(object) as Array<[keyof Value, unknown]>;
    for (const [key, value] of entries) deleteOrRecurse(object, key, value);

    return object;
}

function deleteOrRecurse<Value extends object>(
    object: Value,
    key: keyof Value,
    value: unknown,
): void {
    if (value === null) {
        delete object[key];
    } else if (typeof value === 'object') {
        removeNulls(value);
    }
}
