import { Inject, Injectable } from '@angular/core';
import { gql } from 'graphql-tag';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { GraphqlMutation } from './graphql-mutation';
import { AccountPlanInfo } from '@lib/shared-interface-account';
import { Uuid } from '@lib/shared-interface-utility-types';

@Injectable({
    providedIn: 'root',
})
export class SaveAccountPlanInfoGql extends GraphqlMutation<Variables, Result> {
    public override readonly document = gql`
        mutation SaveSubscribedPlan($accountPlanInfo: SaveAccountSubscriberPlanGqlInput!) {
            saveSubscribedPlan(input: $accountPlanInfo) {
                id
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Variables = { accountPlanInfo: AccountPlanInfo };
type Result = Record<'saveSubscribedPlan', { id: Uuid }>;
