export class GraphqlErrorsException extends Error {
    public constructor(public readonly errors: GraphqlError[]) {
        const message = `GraphQL response contains ${errors.length} errors.`;
        super(message);
    }
}

export type GraphqlResponse<Data> =
    | {
          data: Data;
          errors?: GraphqlError[];
      }
    | {
          data: null;
          errors: GraphqlError[];
      };

export type GraphqlError = {
    message: string;
    extensions: {
        code: string;
        response: {
            statusCode: number;
            message: string;
            error: string;
        };
    };
};
