import { AppDatabase, getAppDatabase } from './app-database/get-app-database';
import {
    BehaviorSubject,
    filter,
    from,
    map,
    mergeMap,
    Observable,
    shareReplay,
    Subject,
    switchMap,
} from 'rxjs';
import { Account, AccountUpdate } from '@lib/shared-interface-account';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { AccountDatabase, getAccountDatabase } from './account-database/get-account-database';
import { Email, Uuid } from '@lib/shared-interface-utility-types';
import { mapArray, mapSortArray } from '@lib/shared-util-rxjs';
import { MangoQuery, MangoQueryNoLimit, RxChangeEvent } from 'rxdb';
import { Contact, ContactDetails, ContactUpdate } from '@lib/shared-interface-contact';
import {
    CreateAskInput,
    createRxdbAskInsert,
} from './account-database/asks-collection/ask-helpers';
import { Inject, Injectable, NgZone } from '@angular/core';
import * as uuid from 'uuid';
import { DOCUMENT } from '@angular/common';
import { Ask, AskUpdate } from '@lib/shared-interface-ask';
import {
    startAccountDatabaseReplication,
    stopAccountDatabaseReplication,
} from './account-database/account-database-replication-helpers';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '@lib/didit-authentication-graphql-client';
import {
    AskFilters,
    createAskQuery,
    getSearchPattern,
} from './account-database/asks-collection/ask-filter-helpers';
import {
    CommonSyncOptions,
    startAppDatabaseReplication,
    stopAppDatabaseReplication,
} from './app-database/app-database-replication-helpers';
import { AccountDatabaseJanitor } from './account-database/account-database-janitor';
import { SubscriberPlan } from '@lib/shared-interface-subscriber-plan';
import { Duration } from 'luxon';

const cleanupFrequency = Duration.fromObject({ day: 1 }).as('millisecond');
const maxAge = Duration.fromObject({ month: 1 }).as('millisecond');

@Injectable({ providedIn: 'root' })
export class DatabaseService {
    public readonly stopAccountDatabaseReplication = stopAccountDatabaseReplication;
    public readonly stopAppDatabaseReplication = stopAppDatabaseReplication;

    private readonly appDatabase$: Observable<AppDatabase> = from(getAppDatabase());
    // Making this a signal allows us to externally reconfigure these values as needed later on.
    // Todo: we need to determine if these options will be configured at the account level
    //  or at the application level.
    private readonly trashPolicy = new BehaviorSubject({ cleanupFrequency, maxAge });

    /**
     * Watch changes to all account references.
     */
    public readonly accountReferences$: Observable<AccountReference[]> = patchRxdbZoneIssue(
        this.appDatabase$.pipe(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            map(({ account_references }) => account_references.find()),
            switchMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
        ),
        this.zone,
    ).pipe(
        // Avoid running through previous steps again on late subscription.
        shareReplay(1),
    );

    public readonly plans$: Observable<SubscriberPlan[]> = this.appDatabase$.pipe(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        map(({ subscriber_plans }) => {
            return subscriber_plans.find();
        }),
        switchMap((query) => query.$),
        mapArray((document) => {
            return document.toMutableJSON();
        }),
        map((plans) => {
            return plans.sort((a, b) => a.order - b.order);
        }),
        shareReplay(1),
    );

    public constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) private graphqlDomain: GraphqlDomain,
        private readonly zone: NgZone,
    ) {
        // If the below logic makes it difficult to test,
        // we can move it to a non-blocking `APP_INITIALIZER`.
        void this.connectToExistingAccounts();
        void this.listenAndConnectToNewAccounts();
    }

    public async findAccountReferenceByAuthenticationId(
        authenticationId: string,
    ): Promise<AccountReference | undefined> {
        const database = await getAppDatabase();
        const selectByAuthenticationIdQuery: MangoQueryNoLimit<AccountReference> = {
            selector: { authenticationId },
        };
        const query = database.account_references.findOne(selectByAuthenticationIdQuery);
        const document = await query.exec();

        return document ?? undefined;
    }

    public async findFirstAccountReference(): Promise<AccountReference | undefined> {
        const database = await getAppDatabase();
        const query = database.account_references.findOne();
        const document = await query.exec();

        return document ?? undefined;
    }

    public async patchAccount(id: Uuid, patch: AccountUpdate): Promise<void> {
        const database = await getAccountDatabase(id);
        // There should always be only one account document in the collection.
        const query = database.account.findOne();
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Account with id ${id} does not exist. Cannot update.`);

        // Incremental methods help avoid conflicts.
        await document.incrementalPatch(patch);
    }

    public async removeAsk(accountId: Uuid, askId: Uuid): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = { selector: { id: askId } };
        const query = database.asks.findOne(selectById);
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Ask with id ${accountId} not found. Cannot delete.`);

        // Incremental methods help avoid conflicts.
        await document.incrementalRemove();
    }

    /**
     * Patch an ask.
     * @param accountId
     * @param askId
     * @param patch
     *  To clear a property, the patch must explicitly set cleared properties to `undefined`.
     */
    public async patchAsk(accountId: Uuid, askId: Uuid, patch: AskUpdate): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = { selector: { id: askId } };
        const query = database.asks.findOne(selectById);
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Ask with id ${askId} not found. Cannot update.`);

        await document.incrementalPatch(patch);
    }

    /**
     * Add an account reference.
     *
     * If the account reference already exists, nothing happens.
     * Adding an account reference will automatically trigger creating an account database.
     * @param accountReference
     */
    public async addAccountReference(accountReference: AccountReference): Promise<void> {
        const { id } = accountReference;
        const database = await getAppDatabase();
        const collection = database.account_references;
        const selectByIdQuery: MangoQueryNoLimit<AccountReference> = {
            selector: { id },
        };
        const query = collection.findOne(selectByIdQuery);
        const existingAccountReference = await query.exec();

        if (existingAccountReference != undefined) return;
        await collection.insert(accountReference);
    }

    public async bulkDeleteAsks(accountId: Uuid, askIds: Uuid[]): Promise<void> {
        const database = await getAccountDatabase(accountId);
        await database.asks.bulkRemove(askIds);
    }

    public async createAsk(accountId: Uuid, createInput: CreateAskInput): Promise<Uuid> {
        const database = await getAccountDatabase(accountId);
        const ask = createRxdbAskInsert(createInput);

        await database.asks.insert(ask);

        return ask.id;
    }

    public watchAsksByFilters(accountId: Uuid, filters: AskFilters): Observable<Ask[]> {
        const database$ = this.getAccountDatabase$(accountId);
        const selectByFilterQuery = createAskQuery(accountId, filters);
        const asks$ = database$.pipe(
            map(({ asks }) => asks.find(selectByFilterQuery)),
            mergeMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
            mapSortArray(moveWhenRequestedByToStart),
        );

        return patchRxdbZoneIssue(asks$, this.zone);
    }

    public async createContact(accountId: Uuid, contactDetails: ContactDetails): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const id = uuid.v4();
        const updatedAt = Date.now();
        const contact = { id, updatedAt, ...contactDetails };

        await database.contacts.insert(contact);
    }

    public async patchContact(
        accountId: Uuid,
        contactId: Uuid,
        patch: ContactUpdate,
    ): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = {
            selector: { id: contactId },
        };
        const query = database.contacts.findOne(selectById);
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Contact with id ${contactId} not found. Cannot update.`);

        await document.incrementalPatch(patch);
    }

    public async removeContact(accountId: Uuid, contactId: Uuid): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = {
            selector: { id: contactId },
        };
        const query = database.contacts.findOne(selectById);
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Contact with id ${contactId} not found. Cannot delete.`);

        // Incremental methods help avoid conflicts.
        await document.incrementalRemove();
    }

    public async restoreAsk(accountId: Uuid, askId: Uuid): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = {
            selector: { id: askId },
        };
        const query = database.asks.findOne(selectById);
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Ask with id ${askId} not found. Cannot restore.`);

        await document.incrementalUpdate({ $unset: { trashedAt: '' } });
    }

    public async trashAsk(accountId: Uuid, askId: Uuid): Promise<void> {
        const database = await getAccountDatabase(accountId);
        const selectById: MangoQueryNoLimit<Ask> = {
            selector: { id: askId },
        };
        const query = database.asks.findOne(selectById);
        const document = await query.exec();

        if (document == undefined) throw new Error(`Ask with id ${askId} not found. Cannot trash.`);

        await document.incrementalPatch({ trashedAt: Date.now() });
    }

    public watchContacts(accountId: Uuid): Observable<Contact[]> {
        const database$ = this.getAccountDatabase$(accountId);
        const queryOptions: MangoQuery<Contact> = {
            sort: [{ firstName: 'asc' }, { lastName: 'asc' }],
        };
        const contacts$ = database$.pipe(
            map(({ contacts }) => contacts.find(queryOptions)),
            mergeMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
        );

        return patchRxdbZoneIssue(contacts$, this.zone);
    }

    public watchContactsBySearch(accountId: Uuid, searchText: string): Observable<Contact[]> {
        const $regex = getSearchPattern(searchText);
        const $options = 'i';
        const database$ = this.getAccountDatabase$(accountId);
        const selectBySearch: MangoQuery<Contact> = {
            selector: {
                $or: [
                    { firstName: { $regex, $options } },
                    { lastName: { $regex, $options } },
                    { companyName: { $regex, $options } },
                    { email: { $regex, $options } },
                    { phone: { $regex, $options } },
                ],
            },
            sort: [{ firstName: 'asc' }, { lastName: 'asc' }, { email: 'asc' }],
        };
        const contacts$ = database$.pipe(
            map(({ contacts }) => contacts.find(selectBySearch)),
            mergeMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
        );

        return patchRxdbZoneIssue(contacts$, this.zone);
    }

    public async findContactByEmail(
        accountId: Uuid,
        contactEmail: Email,
    ): Promise<Contact | undefined> {
        const database = await getAccountDatabase(accountId);
        const selectByEmail: MangoQueryNoLimit<Contact> = {
            selector: { email: contactEmail },
        };
        const query = database.contacts.findOne(selectByEmail);
        const document = await query.exec();
        if (document == undefined) return undefined;

        return document.toMutableJSON();
    }

    public watchAsks(accountId: Uuid): Observable<Ask[]> {
        const database$ = this.getAccountDatabase$(accountId);
        const asks$ = database$.pipe(
            map(({ asks }) => asks.find()),
            switchMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
        );

        return patchRxdbZoneIssue(asks$, this.zone);
    }

    public watchAsk(accountId: Uuid, askId: Uuid): Observable<Ask | undefined> {
        const database$ = this.getAccountDatabase$(accountId);
        const selectById: MangoQueryNoLimit<Ask> = { selector: { id: askId } };
        const ask$ = database$.pipe(
            map(({ asks }) => asks.findOne(selectById)),
            switchMap((query) => query.$),
            map((document) => document?.toMutableJSON()),
        );

        return patchRxdbZoneIssue(ask$, this.zone);
    }

    public watchAccount(accountId: Uuid): Observable<Account | undefined> {
        const database$ = this.getAccountDatabase$(accountId);
        const account$ = database$.pipe(
            map(({ account }) => account.findOne()),
            switchMap((query) => query.$),
            map((document) => document?.toMutableJSON()),
        );

        return patchRxdbZoneIssue(account$, this.zone);
    }

    public watchContact(accountId: Uuid, contactId: Uuid): Observable<Contact | undefined> {
        const database$ = this.getAccountDatabase$(accountId);
        const selectById: MangoQueryNoLimit<Contact> = { selector: { id: contactId } };
        const contact$ = database$.pipe(
            map(({ contacts }) => contacts.findOne(selectById)),
            switchMap((query) => query.$),
            map((document) => document?.toMutableJSON()),
        );

        return patchRxdbZoneIssue(contact$, this.zone);
    }

    public async startAccountDatabaseReplication(accountId: Uuid, token: string): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
            headers: {
                authorization: token,
            },
        };

        // Pull up account database.
        const database = await getAccountDatabase(accountId);

        startAccountDatabaseReplication(accountId, database, commonOptions);
    }

    public async startAppDatabaseReplication(): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
        };

        // Pull up the app database.
        const database = await getAppDatabase();

        startAppDatabaseReplication(database, commonOptions);
    }

    private getAccountDatabase$(id: Uuid): Observable<AccountDatabase> {
        const database = getAccountDatabase(id);
        return from(database);
    }

    private async connectAccountDatabase(id: Uuid): Promise<void> {
        const accountDatabase = await getAccountDatabase(id);

        // Listen for changes to the actual account data.
        // If duplicate data changes, patch the account reference.
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { account_references } = await getAppDatabase();
        const selectById: MangoQueryNoLimit<AccountReference> = {
            selector: { id },
        };
        const query = account_references.findOne(selectById);
        const accountReference = await query.exec();

        // This is mainly type-guarding; this should never happen.
        if (accountReference == undefined) return;

        const updateAccountReference = (changeEvent: RxChangeEvent<Account>) => {
            const { label, email, firstName, lastName, avatar } = changeEvent.documentData;
            const patchData = { label, email, firstName, lastName, avatar };
            const latestAccountReference = accountReference.getLatest();

            if (changeEvent.operation === 'DELETE') {
                void latestAccountReference.incrementalRemove();
                return;
            }
            void latestAccountReference.incrementalPatch(patchData);
        };

        // If the account changes and the data duplicated on an account reference is changed,
        // then we get that duplicate data and patch the account reference with it.
        // This way, when syncing or updating locally,
        // the label/email in the account menu always stays up to date.
        const changes$ = patchRxdbZoneIssue(accountDatabase.account.$, this.zone);
        const duplicateDataUpdate$ = changes$.pipe(filter(isUpdateToAccountReference));

        duplicateDataUpdate$.subscribe(updateAccountReference);

        // Configuration beyond this point will only occur
        // if this account database is elected the leader.
        // This might never occur if this tab is closed before the current leader is closed.
        await accountDatabase.waitForLeadership();

        const janitor = AccountDatabaseJanitor.createFor(accountDatabase);

        // If we change the trash policy at runtime, such as with a change in account configuration,
        // this effect will re-run and re-start the maintenance cycle.
        this.trashPolicy.subscribe(({ cleanupFrequency, maxAge }) => {
            janitor.startMaintenance(cleanupFrequency, maxAge);
        });
    }

    private async connectToExistingAccounts(): Promise<void> {
        // This does not wait for initial database connections, it only initiates their connection.
        const appDatabase = await getAppDatabase();
        const query = appDatabase.account_references.find();
        const accountReferences = await query.exec();
        const connectDatabase = ({ id }: AccountReference) => this.connectAccountDatabase(id);

        void accountReferences.map(connectDatabase);
    }

    private async listenAndConnectToNewAccounts(): Promise<void> {
        // Listen for changes to account references
        // and decide to connect/disconnect the corresponding account database.
        const appDatabase = await getAppDatabase();
        const handleChangeEvent = (changeEvent: RxChangeEvent<AccountReference>) => {
            const { operation, documentData } = changeEvent;
            if (operation === 'INSERT') void this.connectAccountDatabase(documentData.id);
            // Todo: handle update/delete change events;
            //  e.g. when an account reference is deleted,
            //  do we disconnect or delete the associated account database?
            //  Maybe we eventually need a "logged in" property stored on an account reference?
        };

        const changes$ = patchRxdbZoneIssue(appDatabase.account_references.$, this.zone);
        changes$.subscribe(handleChangeEvent);
    }
}

function isUpdateToAccountReference(changeEvent: RxChangeEvent<Account>) {
    const { operation, previousDocumentData, documentData } = changeEvent;
    if (operation !== 'UPDATE') return true;
    return duplicatedDataChanged(previousDocumentData, documentData);
}

function duplicatedDataChanged(previous: DuplicatedAccountData, current: DuplicatedAccountData) {
    return (
        previous.label !== current.label ||
        previous.avatar !== current.avatar ||
        previous.email !== current.email ||
        previous.firstName !== current.firstName ||
        previous.lastName !== current.lastName
    );
}

function moveWhenRequestedByToStart(a: Ask, b: Ask): number {
    // This is tricky but necessary.
    // With RxDB/MangoQuery,
    // when sorting `whenRequestedBy.date` and then `whenRequestedBy.time` as 'asc',
    // the undefined/null `whenRequestedBy`'s are placed first in the array.
    // We want to place them last, but otherwise keep them sorted as is.

    // This reads as the following:
    // When comparing object to null, put the object first;
    if (a.whenRequestedBy != undefined && b.whenRequestedBy == undefined) return -1;
    // When comparing null to object, put the null last;
    if (a.whenRequestedBy == undefined && b.whenRequestedBy != undefined) return 1;
    // Otherwise, keep the order sorted as is.
    return 0;
}

/**
 * Observables originating from RxDB appear to not respect Angular zones.
 * This method patches that oversight.
 *
 * Applying this at the boundary of data coming from the database service
 * means that individual components don't have to worry about it.
 *
 * @deprecated Todo: This is a patch we would like to remove after the switch to signals.
 * @param source The observable which is ultimately originating from RxDB.
 * @param zone The Angular Zone in which we want the next file to run.
 */
function patchRxdbZoneIssue<Value>(source: Observable<Value>, zone: NgZone): Observable<Value> {
    const copy = new Subject<Value>();
    const runInZone = (value: Value) => zone.run(() => copy.next(value));

    source.subscribe(runInZone);

    return copy;
}

type DuplicatedAccountData = Pick<
    AccountReference,
    'label' | 'email' | 'firstName' | 'lastName' | 'avatar'
>;
