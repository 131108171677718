import { Enum, JsonSchema, Required } from 'ts-decorator-json-schema-generator';
import { AskStatus, AskStatusChange } from '@lib/shared-interface-ask';

@JsonSchema({
    title: 'StatusChanges Schema',
    description: 'An asks statusChanges.',
})
export class StatusChangesSchema implements AskStatusChange {
    @Required()
    public readonly timestamp!: number;

    @Required()
    @Enum(AskStatus)
    public readonly status!: AskStatus;
}
