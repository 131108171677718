import { inject, Provider } from '@angular/core';
import { AccountsService, ActiveAccountIdStore } from '@lib/didit-accounts-data-accounts-service';
import { AuthenticationService } from '@lib/didit-authentication-data-authentication-service';
import { provideAppInitializer } from '@lib/didit-shared-util-miscellaneous-providers';

export const ACTIVE_ACCOUNT_ID_INITIALIZER: Provider = provideAppInitializer(
    createActiveAccountIdInitializer,
);

function createActiveAccountIdInitializer() {
    const activeAccountIdStore = inject(ActiveAccountIdStore);
    const authenticationService = inject(AuthenticationService);
    const accountsService = inject(AccountsService);

    return async () => {
        // If there is nothing in session storage,
        // fall back to the last user logged in from cognito's perspective.
        const storedAccountId = activeAccountIdStore.storedId;
        if (storedAccountId) return activeAccountIdStore.update(storedAccountId);

        const authenticationId = authenticationService.lastAuthUserId;
        if (authenticationId) {
            const activeAccount =
                await accountsService.findAccountReferenceByAuthenticationId(authenticationId);
            if (activeAccount) return activeAccountIdStore.update(activeAccount.id);
        }

        // If it got this far, we can just try to use the first account reference we know about
        // (which might be undefined).
        const account = await accountsService.findFirstAccountReference();
        return activeAccountIdStore.update(account?.id);
    };
}
