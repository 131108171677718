import { Account } from '@lib/shared-interface-account';
import { Ask, Recipient } from './ask.interface';
import { AssignmentStrategy, RecipientAskStatus } from './ask-enums.interface';

export function canAcceptOrDeclineAsk(
    account: Pick<Account, 'id'>,
    ask: Pick<Ask, 'creatorId' | 'recipients' | 'assignmentStrategy'>,
): boolean {
    // User is not the owner
    if (account.id === ask.creatorId) return false;

    // The user is an assignee or watcher
    const assigneesAndWatchers = ask.recipients.filter(isAssigneeOrWatcher);
    if (assigneesAndWatchers.length === 0) return false;

    const isMatchingRecipient = (recipient: Pick<Recipient, 'accountId'>) =>
        recipient.accountId === account.id;
    const recipient = assigneesAndWatchers.find(isMatchingRecipient);
    if (!recipient) return false;

    // The user can accept (or decline) the ask only once
    if (recipient.assignmentId || recipient.currentStatus === RecipientAskStatus.DECLINED)
        return false;

    // Check for other recipients that have accepted
    const acceptors = assigneesAndWatchers.filter((recipient) => recipient.assignmentId);
    if (acceptors.length === 0) return true;

    // Someone has not already accepted it (when assignment strategy is "anyone")
    return ask.assignmentStrategy !== AssignmentStrategy.ANYONE;
}

export function canTrashAsk(account: Pick<Account, 'id'>, ask: Pick<Ask, 'creatorId'>): boolean {
    // Must be the owner
    return account.id === ask.creatorId;
}

function isAssigneeOrWatcher(recipient: Pick<Recipient, 'assignee' | 'watcher'>) {
    return recipient.assignee || recipient.watcher;
}
