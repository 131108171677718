<ion-input
    #phoneNumberInputElement
    (ionBlur)="notifyTouched?.()"
    [autocomplete]="autocomplete"
    [clearInput]="true"
    [formControl]="formGroup.controls.phoneNumber"
    [label]="label"
    [placeholder]="formGroup.controls.countryCode.value | libExamplePhoneNumber"
    aria-label="phone-number"
    fill="outline"
    inputmode="tel"
    labelPlacement="floating"
>
    <ion-button
        (click)="openCountrySelectModal()"
        [disabled]="formGroup.controls.countryCode.disabled"
        fill="clear"
        slot="start"
        type="button"
    >
        <img
            [ngSrc]="formGroup.controls.countryCode.value | libCountryFlagIcon"
            alt="flag icon"
            height="23"
            slot="icon-only"
            width="30"
        />
    </ion-button>
</ion-input>
