import { AfterViewInit, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { IfPlatformIsDirective } from '@lib/didit-shared-ui-platform-directives';
import { SideMenuService } from './side-menu.service';
import {
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    Observable,
    pipe,
    shareReplay,
    startWith,
    UnaryFunction,
} from 'rxjs';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
    ActivatedRouteSnapshot,
    ChildActivationEnd,
    Event,
    Router,
    RouterLink,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    standalone: true,
    selector: 'lib-branded-navigation',
    templateUrl: './branded-navigation.component.html',
    styleUrl: './branded-navigation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IfPlatformIsDirective, IonicModule, RouterLink, TranslateModule, DatePipe],
})
export class BrandedNavigationComponent implements AfterViewInit {
    public readonly includeSideMenu = input(true);
    public readonly socialLinks = input<SocialLink[]>([]);

    protected readonly pageTitle$ = this.router.events.pipe(
        getActivatedChildRouteSnapshot(),
        map((snapshot) => snapshot.title),
    );
    protected readonly pageTitle = toSignal(this.pageTitle$);

    protected readonly showTabs$ = this.router.events.pipe(
        getActivatedChildRouteSnapshot(),
        map(getTabIsShown),
    );
    protected readonly showTabs = toSignal(this.showTabs$, { initialValue: true });

    protected readonly version$ = this.httpClient
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get<NgswJsonData>('/ngsw.json', { params: { 'version-cache-bust': Date.now() } })
        .pipe(
            map((data) => data.timestamp),
            shareReplay(1),
        );
    protected readonly version = toSignal(this.version$);
    protected readonly sideMenuOpen = toSignal(this.sideMenuService.sideMenuOpen$);

    protected readonly isLargeScreen$: Observable<boolean> = this.platform.resize.pipe(
        startWith(undefined),
        map(() => this.platform.width() > 1560),
        distinctUntilChanged(),
        takeUntilDestroyed(),
    );

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly platform: Platform,
        private readonly router: Router,
        protected readonly sideMenuService: SideMenuService,
    ) {}

    public ngAfterViewInit() {
        if (this.includeSideMenu()) {
            this.isLargeScreen$.subscribe((isLarge) => {
                this.sideMenuService.setOpen(isLarge);
            });
        }
    }

    public async closeWhenSmall() {
        const isLarge = await firstValueFrom(this.isLargeScreen$);
        if (isLarge) return;

        this.sideMenuService.setOpen(false);
    }
}

export function getActivatedChildRouteSnapshot(): UnaryFunction<
    Observable<Event>,
    Observable<ActivatedRouteSnapshot>
> {
    return pipe(
        filter((event): event is ChildActivationEnd => event instanceof ChildActivationEnd),
        map((event) => {
            let snapshot = event.snapshot;
            while (snapshot.firstChild) snapshot = snapshot.firstChild;
            return snapshot;
        }),
    );
}

function getTabIsShown(snapshot: ActivatedRouteSnapshot) {
    const hideTabNavigation: unknown = snapshot.data['hideTabNavigation'];

    if (hideTabNavigation == undefined) return true;
    if (typeof hideTabNavigation === 'boolean') return !hideTabNavigation;

    const ifQueryParameter =
        typeof hideTabNavigation === 'object' &&
        'ifQueryParameter' in hideTabNavigation &&
        typeof hideTabNavigation.ifQueryParameter === 'string'
            ? hideTabNavigation.ifQueryParameter
            : undefined;
    if (ifQueryParameter) return !snapshot.queryParamMap.has(ifQueryParameter);

    return true;
}

type NgswJsonData = {
    timestamp: number;
};

type SocialLink = {
    href: string;
    iconName?: string;
    iconSource?: string;
};
