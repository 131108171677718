import { addRxPlugin } from 'rxdb';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';

// Adds multi-tab support.
addRxPlugin(RxDBLeaderElectionPlugin);
// Adds cleanup policies for created databases.
addRxPlugin(RxDBCleanupPlugin);
// Adds mongo-like update syntax
addRxPlugin(RxDBUpdatePlugin);
