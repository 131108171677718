import { Inject, Injectable } from '@angular/core';
import {
    AuthenticatedAccountReference,
    AuthenticationService,
    AuthenticationState,
} from '@lib/didit-authentication-data-authentication-service';
import { DatabaseService } from '@lib/didit-shared-data-database-service';
import { ActiveAccountIdStore } from '@lib/didit-accounts-data-accounts-service';
import {
    combineLatest,
    concatMap,
    first,
    firstValueFrom,
    fromEvent,
    map,
    merge,
    mergeMap,
    Observable,
    OperatorFunction,
    shareReplay,
    switchMap,
    UnaryFunction,
} from 'rxjs';
import { Uuid } from '@lib/shared-interface-utility-types';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { DOCUMENT } from '@angular/common';
import { OnlineStatusService } from '@lib/didit-shared-data-online-status-service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public readonly allAccountReferences$: Observable<AuthenticatedAccountReference[]> =
        combineLatest([
            this.authenticationService.authenticationStates$,
            this.databaseService.accountReferences$,
            this.activeAccountIdStore.item$,
        ]).pipe(map(createAuthenticatedAccountReferences), shareReplay(1));

    // public readonly plans$ = this.databaseService.

    public readonly activeAccountReference$: Observable<AuthenticatedAccountReference | undefined> =
        this.allAccountReferences$.pipe(mapFind(isActive), shareReplay(1));

    public readonly inactiveAccountReferences$: Observable<AuthenticatedAccountReference[]> =
        this.allAccountReferences$.pipe(mapFilter(isInactive), shareReplay(1));

    private readonly accountActivity$: Observable<AccountActivity> =
        this.authenticationService.authenticationActivity$.pipe(
            mergeMap(({ isLoggedIn, authenticationId }) => {
                const matchesAuthenticationId = (account: AccountReference) =>
                    account.authenticationId === authenticationId;
                const getAccountActivity = (account: AccountReference): AccountActivity => ({
                    accountId: account.id,
                    authenticationId: account.authenticationId,
                    isLoggedIn,
                });

                return this.databaseService.accountReferences$.pipe(
                    map((accountReferences) => accountReferences.find(matchesAuthenticationId)),
                    // There is a possibility that account reference isn't set right away
                    // when logging in on a fresh device (after successful registration elsewhere).
                    first(isDefined),
                    map(getAccountActivity),
                    shareReplay(1),
                );
            }),
        );

    public constructor(
        private readonly activeAccountIdStore: ActiveAccountIdStore,
        private readonly authenticationService: AuthenticationService,
        private readonly databaseService: DatabaseService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly onlineStatus: OnlineStatusService,
    ) {
        // I typically avoid running async logic in the constructor as it makes it harder to test.
        // However, we don't want to wait for a subscription to kick off the authentication.
        // Todo: decide if this goes in an app initializer.
        void this.registerAuthenticationIds();
        this.manageReplication();
    }

    private manageReplication() {
        const cannotReplicate$ = merge(
            fromEvent(this.document, 'pause'),
            this.onlineStatus.wentOffline$,
        );
        const canReplicate$ = merge(
            fromEvent(this.document, 'resume'),
            this.onlineStatus.wentOnline$,
        );

        const startReplication$ = canReplicate$.pipe(
            switchMap(async () => this.databaseService.startAppDatabaseReplication()),
        );

        const pauseReplication$ = cannotReplicate$.pipe(
            switchMap(async () => {
                const accounts = await firstValueFrom(this.allAccountReferences$);

                const accountIds = accounts.map(({ id }) => id);
                const pauses = accountIds.map((id) =>
                    this.databaseService.stopAccountDatabaseReplication(id),
                );
                pauses.push(this.databaseService.stopAppDatabaseReplication());

                await Promise.all(pauses);
            }),
        );
        const handleAccountActivity$ = this.accountActivity$.pipe(
            concatMap(async ({ accountId, isLoggedIn, authenticationId }: AccountActivity) => {
                if (!isLoggedIn)
                    return this.databaseService.stopAccountDatabaseReplication(accountId);

                const token = this.authenticationService.getAccessToken(authenticationId);
                if (!token) {
                    console.warn('No token was provided');
                    return;
                }

                // Start replication or update headers
                return this.databaseService.startAccountDatabaseReplication(accountId, token);
            }),
        );
        void this.databaseService.startAppDatabaseReplication();

        merge(startReplication$, pauseReplication$, handleAccountActivity$).subscribe();
    }

    private async registerAuthenticationIds(): Promise<void> {
        // We need a list of current authentication IDs right away
        // so that we can have their login state "watched" by the authentication service.
        const accountReferences = await firstValueFrom(this.databaseService.accountReferences$);
        const authenticationIds = accountReferences.map((reference) => reference.authenticationId);
        this.onlineStatus.isOnline$.subscribe((isOnline) => {
            if (isOnline) return this.authenticationService.initializeSessions(authenticationIds);
            this.authenticationService.stopTokenRenewal();
        });
    }
}

function createAuthenticatedAccountReferences(
    allAccountInfo: AllAccountInfo,
): AuthenticatedAccountReference[] {
    const [authenticationStates, accountReferences, activeAccountId] = allAccountInfo;
    const createAccountReference = (accountReference: AccountReference) => {
        const matchesAuthenticationId = (authenticationState: AuthenticationState) =>
            authenticationState.authenticationId === accountReference.authenticationId;
        const isLoggedIn = authenticationStates.find(matchesAuthenticationId)?.isLoggedIn ?? false;
        const isActive = accountReference.id === activeAccountId;

        return { ...accountReference, isLoggedIn, isActive };
    };

    return accountReferences.map(createAccountReference);
}

function mapFind<Item>(
    find: UnaryFunction<Item, boolean>,
): OperatorFunction<Item[], Item | undefined> {
    return map((items) => items.find(find));
}

function isActive(account: AuthenticatedAccountReference): boolean {
    return account.isActive;
}

function mapFilter<Item>(filter: UnaryFunction<Item, boolean>): OperatorFunction<Item[], Item[]> {
    return map((items) => items.filter(filter));
}

function isInactive(account: AuthenticatedAccountReference): boolean {
    return !account.isActive;
}

function isDefined<T>(value?: T): value is T {
    return value != undefined;
}

type AllAccountInfo = [
    authenticationStates: AuthenticationState[],
    accountReferences: AccountReference[],
    activeAccountId: Uuid | undefined,
];

type AccountActivity = {
    accountId: Uuid;
    authenticationId: Uuid;
    isLoggedIn: boolean;
};
