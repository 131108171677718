import { InjectionToken, Provider } from '@angular/core';

export type StripeConfiguration = {
    publishableKey: string;
};

export const STRIPE_CONFIGURATION_TOKEN = new InjectionToken<StripeConfiguration>(
    'StripeConfiguration',
);

export function provideStripeConfiguration(configuration: StripeConfiguration): Provider {
    return {
        provide: STRIPE_CONFIGURATION_TOKEN,
        useValue: configuration,
    };
}
