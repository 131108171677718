import { Enum, JsonSchema, Required } from 'ts-decorator-json-schema-generator';
import { RecipientAskStatus, RecipientAskStatusChange } from '@lib/shared-interface-ask';

@JsonSchema({
    title: 'Recipient Status Changes Schema',
    description: 'Status changes of the ask recipients',
})
export class RecipientStatusChangesSchema implements RecipientAskStatusChange {
    @Required()
    public readonly timestamp!: number;

    @Required()
    @Enum(RecipientAskStatus)
    public readonly status!: RecipientAskStatus;
}
