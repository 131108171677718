import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable } from 'rxjs';

export function provideAppInitializer<Dependencies>(
    getInitializer: (...dependencies: Dependencies[]) => AppInitializer,
    ...dependencies: unknown[]
): Provider {
    return {
        provide: APP_INITIALIZER,
        useFactory: getInitializer,
        multi: true,
        deps: dependencies,
    };
}

type AppInitializer = () => Observable<unknown> | Promise<unknown> | void;
