import {
    Default,
    Enum,
    Items,
    JsonSchema,
    MinLength,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { DateTimeZone, Uuid } from '@lib/shared-interface-utility-types';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { DateTimeZoneSchema } from './date-time-zone.schema';
import { RequesterSchema } from './requester.schema';
import {
    Ask,
    AskStatus,
    AskStatusChange,
    AssignmentStrategy,
    Recipient,
    Requester,
} from '@lib/shared-interface-ask';
import { StatusChangesSchema } from './status-changes.schema';
import { RecipientSchema } from './recipient.schema';

@JsonSchema({
    title: 'Ask Schema',
    description: 'An ask.',
})
class AskSchema implements Ask {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Required()
    @UuidFormat()
    public readonly creatorId!: Uuid;

    @Required()
    @Type('integer')
    public readonly createdAt!: number;

    @Required()
    @Type('integer')
    public readonly updatedAt!: number;

    @Required()
    @MinLength(1)
    public readonly title!: string;

    @Optional()
    public readonly description?: string;

    @Optional()
    @Type(RequesterSchema)
    public readonly requester?: Requester;

    @Optional()
    @Items(RecipientSchema)
    @Default([])
    public readonly recipients!: Recipient[];

    @Optional()
    @Type(DateTimeZoneSchema)
    public readonly whenRequestedBy?: DateTimeZone;

    @Optional()
    public readonly recurrencePattern?: string;

    @Required()
    @Items(StatusChangesSchema)
    @Default([])
    public readonly statusChanges!: AskStatusChange[];

    @Optional()
    @Type('integer')
    public readonly sentAt?: number;

    @Optional()
    @Type('integer')
    public readonly trashedAt?: number;

    @Optional()
    @Type('integer')
    public readonly completedAt?: number;

    @Required()
    @Enum(AskStatus)
    public readonly currentStatus!: AskStatus;

    @Optional()
    @Enum(AssignmentStrategy)
    public readonly assignmentStrategy?: AssignmentStrategy;
}

export const ASK_SCHEMA = generateRxdbSchema(AskSchema, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
