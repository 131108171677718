import { Inject, Injectable } from '@angular/core';
import { GraphqlMutation } from './graphql-mutation';
import { gql } from 'graphql-tag';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { Uuid } from '@lib/shared-interface-utility-types';

@Injectable({ providedIn: 'root' })
export class NotifyAssigneesGql extends GraphqlMutation<Variables, Result> {
    public override readonly document = gql`
        mutation SaveBasicAccountInfo($askId: ID!) {
            notifyAssignees(askId: $askId)
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN)
        graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Variables = { askId: Uuid };
type Result = Record<'notifyAssignees', boolean>;
