import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectorRef, forwardRef, ForwardRefFn, inject, Provider } from '@angular/core';

export abstract class BaseControl<ControlValue> implements ControlValueAccessor {
    protected notifyChanged?: ChangeNotifier<ControlValue>;
    protected notifyTouched?: TouchedNotifier;
    protected isDisabled?: boolean;

    readonly #changeDetector = inject(ChangeDetectorRef);

    public abstract writeValue(value: ControlValue): void;

    public registerOnChange(notifyChanged: ChangeNotifier<ControlValue>) {
        this.notifyChanged = notifyChanged;
    }

    public registerOnTouched(notifyTouched: TouchedNotifier) {
        this.notifyTouched = notifyTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.#changeDetector.markForCheck();
    }
}

export function provideValueAccessor(getForwardReference: ForwardRefFn): Provider {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(getForwardReference),
        multi: true,
    };
}

export function provideValidator(getForwardReference: ForwardRefFn): Provider {
    return {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(getForwardReference),
        multi: true,
    };
}

type ChangeNotifier<Value> = (value: Value) => void;
type TouchedNotifier = () => void;
