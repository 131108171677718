import { inject } from '@angular/core';
import { provideAppInitializer } from '@lib/didit-shared-util-miscellaneous-providers';
import { AppUpdateDurations, AppUpdateService } from './app-update.service';

// To test this behavior out in a non-production environment,
// first make sure you have the service worker enabled for local development.
// Then you might have to run `pnpm start <frontend> --liveReload false`.
// After that, it may be necessary to refresh the page.
// You can verify the service worker is installed by checking the dev-tools application tab.
export function provideAppUpdateInitializer(durationOverrides?: Partial<AppUpdateDurations>) {
    const normalizedOptions: AppUpdateDurations = {
        checkForUpdate: 60,
        restartReminder: 30,
        newVersionWarning: 60,
        ...durationOverrides,
    };
    return provideAppInitializer(() => createInitializer(normalizedOptions));
}

function createInitializer(durations: AppUpdateDurations) {
    const service = inject(AppUpdateService);
    return () => service.initialize(durations);
}
