import { Uuid } from '@lib/shared-interface-utility-types';
import { AsksCollection } from '../get-account-database';
import {
    getPullQueryBuilder,
    getPullStreamQueryBuilder,
    getPushQueryBuilder,
} from '../account-database-replication-helpers';
import { REPLICATION_CHECKPOINT_GQL_FRAGMENT } from '../../shared/replication-checkpoint-gql-fragment';
import { removeNulls } from '../../shared/remove-nulls';

export function createAskReplicationOptions(accountId: Uuid, collection: AsksCollection) {
    const limit = 50; // TODO: determine correct limit for asks
    const pullQueryBuilder = getPullQueryBuilder(PULL_ASKS_GQL, { accountId, limit });
    const pushStreamQueryBuilder = getPushQueryBuilder(PUSH_ASKS_GQL, { accountId });
    const pullStreamQueryBuilder = getPullStreamQueryBuilder(STREAM_ASKS_GQL, { accountId });

    return {
        collection,
        pull: {
            queryBuilder: pullQueryBuilder,
            streamQueryBuilder: pullStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
            includeWsHeaders: true,
        },
        push: {
            queryBuilder: pushStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
        },
        deletedField: 'deleted',
        live: true,
        // 5 seconds
        retryTime: 1000 * 5,
        waitForLeadership: true,
        autoStart: true,
    };
}

const DOCUMENT_GQL_FRAGMENT = /* GraphQL */ `
    fragment ReplicatedAskProperties on ReplicatedAsk {
        id
        updatedAt
        deleted
        createdAt
        creatorId
        description
        title
        trashedAt
        recurrencePattern
        sentAt
        assignmentStrategy
        whenRequestedBy {
            timezone
            time
            date
        }
        requester {
            accountId
            acceptance
        }
        statusChanges {
            timestamp
            status
            note
        }
        currentStatus
        recipients {
            accountId
            email
            assignee
            completed
            contactId
            editor
            firstName
            lastName
            statusChanges {
                note
                status
                timestamp
            }
            watcher
            whenPlannedBy {
                date
                time
                timezone
            }
        }
    }
`;

const PULL_ASKS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    query PullAsks($accountId: ID!, $checkpoint: ReplicationCheckpointInput, $limit: Int) {
        asksPull(accountId: $accountId, checkpoint: $checkpoint, limit: $limit) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedAskProperties
            }
        }
    }
`;

const PUSH_ASKS_GQL = /* GraphQL */ `
    ${DOCUMENT_GQL_FRAGMENT}
    mutation PushAsks($accountId: ID!, $rows: [AskReplicationPushRow!]!) {
        pushAsks(accountId: $accountId, rows: $rows) {
            ...ReplicatedAskProperties
        }
    }
`;

const STREAM_ASKS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    subscription StreamAsks($accountId: ID!, $authorization: String!) {
        asksChanged(accountId: $accountId, authorization: $authorization) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedAskProperties
            }
        }
    }
`;
