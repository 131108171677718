import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { map, Observable, Subscription } from 'rxjs';
import { AppService } from '@lib/didit-app-data-app-service';
import { AuthenticationService } from '@lib/didit-authentication-data-authentication-service';
import { Uuid } from '@lib/shared-interface-utility-types';
import { GraphqlDomain, GRAPHQL_DOMAIN_URL_TOKEN } from '@lib/didit-authentication-graphql-client';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor, OnDestroy {
    private activeAccountAuthenticationId: Uuid | undefined;
    private activeAccountAuthenticationIdSubscription: Subscription;

    public constructor(
        private readonly appService: AppService,
        private readonly authenticationService: AuthenticationService,
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) private graphqlDomain: GraphqlDomain,
    ) {
        this.activeAccountAuthenticationIdSubscription = this.appService.activeAccountReference$
            .pipe(
                map((activeAccountReference) => {
                    return activeAccountReference?.authenticationId;
                }),
            )
            .subscribe((accountId) => {
                this.activeAccountAuthenticationId = accountId;
            });
    }

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        // Only attach header to requests to our API
        if (!request.url.includes(this.graphqlDomain)) {
            return next.handle(request);
        }

        // Only attach header if there is no existing Authorization header.
        // Immediately after login, there's no active account,
        // but the request will already have the header.
        if (request.headers.has('Authorization')) {
            return next.handle(request);
        }

        // Check we have everything we need to attach the header
        if (!this.activeAccountAuthenticationId) {
            return next.handle(request);
        }
        const accessToken = this.authenticationService.getAccessToken(
            this.activeAccountAuthenticationId,
        );
        if (accessToken == undefined) {
            return next.handle(request);
        }

        const amendedRequest = request.clone({
            headers: request.headers.set('Authorization', accessToken),
        });
        return next.handle(amendedRequest);
    }

    public ngOnDestroy(): void {
        this.activeAccountAuthenticationIdSubscription.unsubscribe();
    }
}
