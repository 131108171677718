import { Assignment } from '@lib/shared-interface-assignment';
import * as uuid from 'uuid';
import { RecipientAskStatus } from '@lib/shared-interface-ask';

export type CreateAssignmentInput = Omit<
    Assignment,
    'id' | 'createdAt' | 'resolutionSummary' | 'statusChanges' | 'currentStatus' | 'updatedAt'
>;

export function createRxdbAssignmentInsert(input: CreateAssignmentInput): Assignment {
    const id = uuid.v4();
    const createdAt = Date.now();
    const updatedAt = createdAt;
    const initialStatus = RecipientAskStatus.OPEN;

    return {
        id,
        ...input,
        currentStatus: initialStatus,
        statusChanges: [
            {
                timestamp: createdAt,
                status: initialStatus,
            },
        ],
        createdAt,
        updatedAt,
    };
}
