import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { LanguageSelection } from './i18n.interfaces';
import { LANGUAGE_DEFAULT, LANGUAGE_LIST, STORAGE_KEY_FOR_LANGUAGE_CODE } from './i18n.constants';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    public language = 'en';
    public constructor(
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public changeLanguage(code: string): void {
        const languageSelection: LanguageSelection | undefined = this.getLanguageSelection(code);
        if (!languageSelection) return;
        // TODO: add this to RXDB
        // sessionStorage.setItem(STORAGE_KEY_FOR_LANGUAGE_CODE, languageSelection.code);
        this.setLanguage(languageSelection.code);
    }

    public getCurrentLanguage(): string {
        return this.translateService.currentLang;
    }

    public initLanguage(): Promise<void> {
        this.translateService.addLangs(LANGUAGE_LIST.map((language) => language.code));
        const language: LanguageSelection | undefined = this.getLanguage();
        const code = language ? language.code : LANGUAGE_DEFAULT.code;
        this.translateService.setDefaultLang(code);
        this.setLanguage(code);
        return Promise.resolve();
    }

    private getLanguage(): LanguageSelection | undefined {
        // TODO: add in RXDB storage here
        // const code = sessionStorage.getItem(STORAGE_KEY_FOR_LANGUAGE_CODE) ?? '';
        const code = this.translateService.getBrowserLang();
        const language: LanguageSelection | undefined = this.getLanguageSelection(code);
        if (language) return language;
        return this.getLanguageSelection(this.translateService.getBrowserLang());
    }

    private getLanguageSelection(code: string | undefined): LanguageSelection | undefined {
        return code
            ? LANGUAGE_LIST.find((language: LanguageSelection) => language.code === code)
            : undefined;
    }

    private setLanguage(languageCode: LanguageSelection['code']): void {
        const setLanguageCodeCallback = this.getLanguageCodeCallback(languageCode);
        this.translateService.use(languageCode).pipe(tap(setLanguageCodeCallback)).subscribe();
    }

    private getLanguageCodeCallback(languageCode: LanguageSelection['code']): () => void {
        return () => {
            this.document.documentElement.setAttribute(STORAGE_KEY_FOR_LANGUAGE_CODE, languageCode);
            this.language = languageCode;
        };
    }
}
