/**
 * Creates a string enum type
 * from union of provided strings.
 *
 * Usage: `StringEnum<'one'|'two'> => { readonly one: 'one', readonly two: 'two' }`
 */
export type StringEnum<Keys extends string> = { readonly [Key in Keys]: Key };

/**
 * Retrieves the values of an enum
 * as a union.
 *
 * Usage: `EnumValues<typeof MyConstObjectEnum>`
 */
export type EnumValues<Type extends object> = Type[keyof Type];
