import { InjectionToken, Provider } from '@angular/core';

export type AppDomain = string;
export const APP_DOMAIN_URL_TOKEN = new InjectionToken<string>('AppDomain');

export function provideClientAppDomainConfiguration(url: string): Provider[] {
    const configurationProvider = {
        provide: APP_DOMAIN_URL_TOKEN,
        useValue: url,
    };
    return [configurationProvider];
}
