import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Injectable, Provider } from '@angular/core';

/**
 * A title strategy
 * which uses the default title
 * defined at `index.html > html > head > title`
 * as the prefix or default title
 * when setting or clearing the new title
 * after a route transition.
 */
@Injectable()
class PrefixedTitleStrategy extends TitleStrategy {
    private readonly defaultTitle = this.title.getTitle();

    public constructor(private title: Title) {
        super();
    }

    public override updateTitle(snapshot: RouterStateSnapshot) {
        const titleSuffix = this.buildTitle(snapshot);

        // All meaningful routes should have a title,
        // but in the rare case
        // that a developer forgets to add one,
        // we use the default title.
        const fullTitle = titleSuffix
            ? getFullTitle(this.defaultTitle, titleSuffix)
            : this.defaultTitle;

        this.title.setTitle(fullTitle);
    }
}

/**
 * Combine a prefix and suffix
 * to build a full title.
 * @param prefix
 * @param suffix
 */
function getFullTitle(prefix: string, suffix: string) {
    return `${prefix} | ${suffix}`;
}

/**
 * Replaces the default title strategy.
 */
export const TITLE_STRATEGY_PROVIDER: Provider = {
    provide: TitleStrategy,
    useClass: PrefixedTitleStrategy,
};
