import { JsonSchema, Max, Min, Optional, Required } from 'ts-decorator-json-schema-generator';
import { DateOfBirth } from '@lib/shared-interface-account';

@JsonSchema({
    title: 'Date of Birth Schema',
    description: "A user's date of birth, but specifying the exact day is optional.",
})
export class DateOfBirthSchema implements DateOfBirth {
    @Required()
    @Min(0)
    public readonly year!: number;

    @Required()
    @Min(1)
    @Max(12)
    public readonly month!: number;

    @Optional()
    @Min(1)
    @Max(31)
    public readonly day?: number;
}
