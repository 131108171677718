import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from 'libphonenumber-js/min';
import { COUNTRY_FLAG_ICON_PATH_TOKEN } from '../provide-country-flag-icon-path';
import { getCountryFlagIcon } from './get-country-flag-icon';

@Pipe({
    name: 'libCountryFlagIcon',
    standalone: true,
})
export class CountryFlagIconPipe implements PipeTransform {
    public constructor(
        @Inject(COUNTRY_FLAG_ICON_PATH_TOKEN) private readonly flagIconPath: string,
    ) {}

    public transform(countryCode: CountryCode): string {
        return getCountryFlagIcon(this.flagIconPath, countryCode);
    }
}
