import {
    Default,
    Format,
    JsonSchema,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { DateTimeZone, Email, Uuid } from '@lib/shared-interface-utility-types';
import { Recipient, RecipientAskStatusChange } from '@lib/shared-interface-ask';
import { RecipientStatusChangesSchema } from './recipient-status-changes.schema';
import { DateTimeZoneSchema } from './date-time-zone.schema';

@JsonSchema({
    title: 'Recipient Schema',
    description: 'A recipient of an Ask',
})
export class RecipientSchema implements Recipient {
    @Optional()
    public readonly accountId?: Uuid;

    @Optional()
    public readonly contactId?: Uuid;

    @Required()
    public readonly name!: string;

    @Required()
    @Format('email')
    public readonly email!: Email;

    @Required()
    @Default(false)
    public readonly watcher!: boolean;

    @Required()
    @Default(false)
    public readonly assignee!: boolean;

    @Required()
    @Default(false)
    public readonly editor!: boolean;

    @Optional()
    @Type(DateTimeZoneSchema)
    public readonly whenPlannedBy?: DateTimeZone;

    @Required()
    @Type(RecipientStatusChangesSchema)
    public readonly statusChanges!: RecipientAskStatusChange[];

    @Required()
    @Default(false)
    public readonly completed!: boolean;
}
