import { replicateGraphQL, RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { AppDatabase } from './get-app-database';
import { createSubscriberPlanReplicationOptions } from './subscriber-plans-collection/create-subscription-plan-replication-options';
import { RxGraphQLReplicationPullQueryBuilder } from 'rxdb';

export function getAppPullQueryBuilder<Checkpoint>(
    query: string,
    constants: {
        limit?: number;
    },
): RxGraphQLReplicationPullQueryBuilder<Checkpoint> {
    const { limit } = constants;
    return (checkpoint?: Checkpoint) => ({
        query,
        variables: { checkpoint, limit },
    });
}

export function getAppPullStreamQueryBuilder(query: string) {
    // Headers only need to be used if we reach the point of authenticating individual accounts.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (headers: Record<string, string>) => {
        const authorization = headers['authorization'];
        return { query, variables: { authorization } };
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
let REPLICATION_STATES: RxGraphQLReplicationState<any, any>[] | undefined;

export function startAppDatabaseReplication(
    database: AppDatabase,
    commonOptions: CommonSyncOptions,
): void {
    // Replication states might already exist.
    let replicationStates = REPLICATION_STATES;
    if (replicationStates != undefined) {
        const headers = commonOptions.headers ?? {};
        for (const state of replicationStates) state.setHeaders(headers);
        return;
    }

    // Turn on replication for all of its collections.
    replicationStates = [];

    const collectionReplicationOptions: CollectionSpecificSyncOptions[] = [
        createSubscriberPlanReplicationOptions(database.subscriber_plans),
    ];
    for (const replicationOptions of collectionReplicationOptions) {
        const fullReplicationOptions = { ...replicationOptions, ...commonOptions };
        const replicationState = replicateGraphQL(fullReplicationOptions);

        replicationStates.push(replicationState);
    }

    REPLICATION_STATES = replicationStates;
}

export async function stopAppDatabaseReplication(): Promise<void> {
    // Pull up the replication state for this account and cancel replication.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (REPLICATION_STATES == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const cancellations = REPLICATION_STATES.map((state) => state.cancel());
    await Promise.allSettled(cancellations);

    REPLICATION_STATES = undefined;
}

export type CommonSyncOptions = Pick<
    SyncOptionsGraphQl,
    'url' | 'headers' | 'replicationIdentifier'
>;

// Todo: should be `SyncOptionsGraphQL<unknown, unknown>`
//  but `SyncOptionsGraphQL` currently isn't exported correctly from rxdb.
type SyncOptionsGraphQl = Parameters<typeof replicateGraphQL>[0];
type CollectionSpecificSyncOptions = Omit<
    SyncOptionsGraphQl,
    'url' | 'headers' | 'replicationIdentifier'
>;
