import { InjectionToken } from '@angular/core';

export const SESSION_STORAGE_TOKEN = new InjectionToken<Storage>('sessionStorage', {
    providedIn: 'root',
    factory: () => sessionStorage,
});

export const LOCAL_STORAGE_TOKEN = new InjectionToken<Storage>('localStorage', {
    providedIn: 'root',
    factory: () => localStorage,
});
